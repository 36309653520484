import { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { ContextGenerator } from '../Utils/ContextUtils'

const useStyleStore = () => {
	const isHeightLarge = useMediaQuery('(min-height: 860px)')
	const isHeightMedium = useMediaQuery('(min-height: 742px)')
	const isHeightSmall = useMediaQuery('(min-height: 600px)')
	const isMobile = useMediaQuery('(max-width: 600px)')
	const [darkMode, setDarkMode] = useState<boolean>(false)
	return (
		{
			isMobile,
			isHeightLarge,
			isHeightMedium,
			isHeightSmall,
			darkMode,
			setDarkMode
		}
	)
}

export const { context: StyleStoreContext, contextMaker: StyleStoreContextMaker } = ContextGenerator(useStyleStore)
