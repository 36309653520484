import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { styled, Typography } from '@mui/material'
import Button from '../Components/Button'
import Grid from '../Components/Grid'
import TextField from '../Components/TextField'
import { CartStoreContext } from '../Controllers/CartStore'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import { UIContext } from '../Controllers/UIContext'
import CartItem from './CartItem'

const CustomTextField = styled(TextField)(({ theme }) => ({
	mt: 4,
	border: '1px solid',
	borderColor: theme.palette.grey[300],
	borderRadius: '.5rem',
	height: 'fit-content'
}))

const Cart = () => {
	const { locale } = useContext(LocalizationContext)
	const { cart, cartNote, setCartNote } = useContext(CartStoreContext)
	const { urlPrefix, teamId, isTotalDemoShop, shopName } = useContext(ShopMetaDataContext)
	const dividePrice = teamId || isTotalDemoShop || shopName
	const { setNavbarTitle, navbarTitle } = useContext(UIContext)
	const navigate = useNavigate()

	const amountOfItems = cart.reduce((acc, item) => acc + item.quantity, 0)
	const cartPrice = cart.reduce((acc, item) => acc + ((item.item.price || 0) / (dividePrice ? 1000 : 1)) * item.quantity, 0)

	useEffect(() => {
		if(navbarTitle !== locale.cart) {
			setNavbarTitle(locale.cart)
		}
	}, [])
	return (
		<Grid
			flexcolumn
			fullheight>
			<Grid
				flexcolumn
				justifyContent='space-between'
				fullheight
				p={4}>
				<Grid flexcolumn>
					<Grid
						flexcolumn
						sx={{ backgroundColor: 'grey.100', p: 2, borderRadius: '.75rem' }}>
						<Typography variant='subtitle2'>
							{`${amountOfItems} ${amountOfItems === 1 ? 'Item' : locale.items}` }
						</Typography>
						<Grid
							display='flex'
							justifyContent='space-between'
							alignItems='center'>
							<Typography variant='body2'>
								{locale.estimatedTotal}
							</Typography>
							<Typography
								variant='body2'
								color='primary'>
								{`${cart[0] ? cart[0].item.currency : ''} ${cartPrice}`}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						wrap='nowrap'
						overflow='scroll'
						flexcolumn
						height='100%'
						maxHeight='calc(100vh - 22rem)'>
						{
							cart.map((cartItem) => (
								<CartItem
									cartItem={cartItem}
									key={cartItem.item.id}
								/>
							))
						}
					</Grid>
				</Grid>
				<Grid
					flexcolumn>
					<CustomTextField
						value={cartNote}
						onChange={(e) => setCartNote(e.target.value)}
						fullWidth
						multiline
						rows={2}
						placeholder={locale.notePlaceholder} />
					<Button
						onClick={() => navigate(`/${urlPrefix}/checkout`)}
						disabled={!cart.length}
						size='full'
						variant='contained'
						bgColor='primary'>
						{locale.checkout}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Cart