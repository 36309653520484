import { useContext, useEffect, useState } from 'react'
import { Configuration, EventsApi } from '@chatdaddy/client'
import axios from 'axios'
import { ContextGenerator } from '../Utils/ContextUtils'
import urls from '../Utils/urls'
import { CartStoreContext } from './CartStore'
import { PaymentStoreContext } from './PaymentIntegrationStore'
import { ProductStoreContext } from './ProductStore'
import { ShopMetaDataContext } from './ShopMetadataStore'

type Order = {
	'params': { [key: string]: string | Date | number | object | null | undefined } // can't use any
	'orderId': string
    'phoneNumber': string
}

type CheckoutProductRequest = {
	'order': Order
	'teamId': string
	'trackingId': string
	'otp': string
}

type Contact = {
	name: string
	mobileNumber: string
}
type ShippingDetails = Contact & {
	shippingAddress: string
	deliveryTime: string
	shippingMethod: 'delivery' | 'pickup' | 'none'
	pickupLocation?: string
	shippingOption?: string
	deliveryFees?: number
}

const useCheckoutStore = () => {
	const { teamId, trackingId } = useContext(ProductStoreContext)
	const { selectedIntegration, resetPayment } = useContext(PaymentStoreContext)
	const { shopMetaData, shopName, isTotalDemoShop } = useContext(ShopMetaDataContext)
	const { cart, resetCart, cartNote, promoCode } = useContext(CartStoreContext)

	const dividePrice = teamId || isTotalDemoShop || shopName
	const cartPrice = cart.reduce(
		(acc, item) => acc + ((item.item.price || 0) / (dividePrice ? 1000 : 1)) * item.quantity,
		0
	)
	const configuration = new Configuration({})
	const notificationsApi = new EventsApi(configuration)

	const { isDemo } = useContext(ShopMetaDataContext)
	const [expanded, setExpanded] = useState<'contact' | 'shippingDetails' | 'paymentMethod' | undefined>('contact')
	const [sameAsContact, setSameAsContact] = useState(true)
	const [contact, setContact] = useState<Contact>({
		name:'',
		mobileNumber:''
	})
	const [shippingDetails, setShippingDetails] = useState<ShippingDetails>({
		name:'',
		mobileNumber:'',
		shippingAddress:'',
		deliveryTime:'',
		shippingMethod: !shopMetaData?.shippingEnabled && !shopMetaData?.pickUpEnabled ? 'none' : shopMetaData?.pickUpEnabled ? 'pickup' : 'delivery',
		deliveryFees: 0,
	})

	const [otp, setOtp] = useState<string>('')

	const generateOtp = async(captchaToken: string) => {
		await axios.post(urls.sendOtp(), { phoneNumber: contact.mobileNumber.startsWith('+') ? contact.mobileNumber : `+${contact.mobileNumber}`, captchaToken })
	}

	const resetCheckout = () => {
		setSameAsContact(false)
		setExpanded('contact')
		setContact({
			name:'',
			mobileNumber:''
		})
		setShippingDetails({
			name:'',
			mobileNumber:'',
			shippingAddress:'',
			deliveryTime:'',
			shippingMethod: 'delivery'
		})
		setOtp('')
		resetCart()
		resetPayment()
	}

	const postOrderToEasysend = async() => {
		const orderBody: CheckoutProductRequest = {
			order: {
				orderId: '', // will create one on the backend
				phoneNumber: contact.mobileNumber,
				params: {
					recipientName: shippingDetails.name,
					recipientPhone: shippingDetails.mobileNumber,

					orderDate: new Date().toJSON(),
					orderStatus: 'pending',
					orderCustomer: contact.name,
					orderCustomerPhone: contact.mobileNumber,

					paymentMethod: selectedIntegration?.name,
					paymentStatus: 'pending',

					shippingMethod: shippingDetails.shippingMethod,
					shippingAddress: shippingDetails.shippingAddress,
					shippingOption: shippingDetails.shippingOption,
					pickupLocation: shippingDetails.pickupLocation,
					deliveryDate: shippingDetails.deliveryTime,
					deliveryStatus: 'pending',

					cartNote,
					promoCode,

					productDetails: cart.map(c => ({
						note: c.note,
						id: c.item.id,
						price: c.item.price,
						quantity: c.quantity,
						productName: c.item.name,
						currency: c.item.currency,
					}))
				}
			},
			teamId: isDemo ? '007206d2-5b5e-4e4a-8033-ba7a56bee87b' : teamId as string,
			trackingId: isDemo ? '20090' : trackingId as string,
			otp,
		}

		await notificationsApi.orderPost({ orderPostRequest: orderBody })
		resetCheckout()
	}

	useEffect(() => {
		if(!shopMetaData) {
			return
		}

		const shippingMethod = !shopMetaData?.shippingEnabled && !shopMetaData?.pickUpEnabled ? 'none' : shopMetaData?.pickUpEnabled ? 'pickup' : 'delivery'
		setShippingDetails({
			...shippingDetails,
			shippingMethod,
			shippingOption: shippingMethod === 'delivery' ? shopMetaData?.shippingFee?.filter(shipping => shipping.enabled)[0]?.name : undefined,
			pickupLocation: shippingMethod === 'pickup' ? shopMetaData?.pickUpAddresses?.filter(a => a.enabled)[0].address : undefined,
		})
	}, [shopMetaData])

	return {
		otp,
		setOtp,
		expanded,
		setExpanded,
		sameAsContact,
		setSameAsContact,
		contact,
		setContact,
		shippingDetails,
		setShippingDetails,
		generateOtp,
		postOrderToEasysend,
		resetCheckout,
		cartPrice,
		freeShipping: shopMetaData?.freeShippingThreshold && cartPrice >= shopMetaData.freeShippingThreshold,
	}
}

export const { context: CheckoutStoreContext, contextMaker: CheckoutStoreContextMaker } = ContextGenerator(useCheckoutStore)
