import { useContext, useEffect, useState } from 'react'
import PhoneInput, { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input'
import { CountryCode } from 'libphonenumber-js/core'
import 'react-phone-number-input/style.css'
import './PhoneNumberInput.css'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import MuiPhoneInput from './MuiPhoneInput'

export type PhoneNumberInputProps = {
	setPhoneNumber: (ph?: string) => void
	phoneNumber: string
	onBlur?: React.FocusEventHandler<'string'>
	initialPhoneNumber: string
	disabled?: boolean
	error?: boolean
}

export default ({ initialPhoneNumber, phoneNumber, disabled, setPhoneNumber, onBlur, error }: PhoneNumberInputProps) => {
	const { userCountry } = useContext(LocalizationContext)
	const [country, setCountry] = useState<string | undefined>(
		initialPhoneNumber ? parsePhoneNumber(initialPhoneNumber)?.country : userCountry
	)
	const [countryCodePrefix, setCountryCodePrefix] = useState('')
	const updatePhoneNumber = (countryCodePrefix: string, number: string) => {
		const ph = number && parsePhoneNumber(number)
		if(ph) {
			setPhoneNumber(`+${countryCodePrefix}${ph.nationalNumber}`)
		}
	}

	useEffect(() => {
		if(initialPhoneNumber && !phoneNumber) {
			const ph = parsePhoneNumber(initialPhoneNumber)
			setPhoneNumber((ph?.nationalNumber || '') as string)
		}
	}, [initialPhoneNumber])

	useEffect(() => {
		try {
			//@ts-ignore
			const code = getCountryCallingCode(country || '')
			if(code !== countryCodePrefix) {
				setCountryCodePrefix(code as string)
				updatePhoneNumber(code as string, phoneNumber)
			}
		} catch{

		}
	}, [country])

	return (
		<PhoneInput
			label='Whatsapp Phone Number'
			id='whatsapp-number'
			disabled={disabled}
			placeholder='Whatsapp Phone Number'
			value={phoneNumber}
			className='phone-input-container'
			international={false}
			onCountryChange={setCountry}
			displayInitialValueAsLocalNumber
			// prefix={undefined}
			defaultCountry={userCountry as CountryCode}
			numberInputProps={{ error: error }}
			inputComponent={MuiPhoneInput}
			country={country}
			//@ts-ignore
			style={Object.assign({ '--country': `'+${countryCodePrefix}'` })}
			onChange={(number) => updatePhoneNumber(countryCodePrefix, number as string)}
			onBlur={onBlur as () => void}
		/>
	)
}
