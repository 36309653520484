import { useContext, useEffect } from 'react'
import { Outlet, useNavigate, useParams, } from 'react-router'
import { LANGUAGE_REGION_MAP, LocalizationContext } from '../Controllers/LocalizationStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'

const Container = () => {
	const { setShopName, setIsDemo, shopName:currentShopName } = useContext(ShopMetaDataContext)
	const { setCurrentLanguage } = useContext(LocalizationContext)
	const { locale, shopName, isDemo } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		if(shopName && currentShopName !== shopName) {
			setShopName(shopName)
		}
	}, [shopName, currentShopName])

	useEffect(() => {
		//HARDCODED FOR SJC111
		if(window.location.hostname === 'www.sjc111.com' && shopName !== 'One & Only One' && window.location.pathname !== '/en/One%20&%20Only%20One/shop/products') {
			setShopName('One & Only One')
			navigate('/en/One%20&%20Only%20One/shop/products')
		}
	}, [shopName])

	useEffect(() => {
		if(isDemo) {
			setIsDemo(isDemo === 'demo')
		}
	}, [isDemo])

	useEffect(() => {
		if(locale) {
			const language = LANGUAGE_REGION_MAP[locale].Language || LANGUAGE_REGION_MAP['en'].Language
			setCurrentLanguage(language)
		}
	}, [locale])

	return (
		<Outlet />
	)
}

export default Container