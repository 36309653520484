import { createContext, createElement, ReactNode } from 'react'

/**
 * Makes a context for a store and a component to provide the store
 */
export function ContextGenerator<T>(store: () => T) {
	const context = createContext<T>(undefined as T)
	return {
		context,
		contextMaker: ({ children }: {children: ReactNode}) => {
			const value = store()
			return createElement(context.Provider, { value }, children)
		},
	}
}