import { useContext } from 'react'
import { Color, ThemeOptions } from '@mui/material'
import { StyleStoreContext } from './Controllers/StyleStore'

export type Variant =
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'subtitle1'
		| 'subtitle2'
		| 'body1'
		| 'body2'
		| 'caption'
		| 'button'
		| 'overline'
		| 'xxSmallRegular'
		| 'xxSmallMedium'
		| 'xSmallRegular'
		| 'xSmallMedium'
		| 'smallRegular'
		| 'smallMedium'
		| 'smallSemiBold'
		| 'baseRegular'
		| 'baseMedium'
		| 'baseSemiBold'
		| 'mediumRegular'
		| 'mediumMedium'
		| 'mediumSemiBold'
		| 'largeRegular'
		| 'largeMedium'
		| 'largeSemiBold'
		| 'xLargeRegular'
		| 'xLargeMedium'
		| 'xLargeSemiBold'
		| 'xxLargeRegular'
		| 'xxLargeMedium'
		| 'xxLargeSemiBold'
declare module '@mui/material/styles' {

	interface Palette {
		coolGrey: Partial<Color>
		primaryGradient: Partial<Color>
		yellow: Partial<Color>
	}

	interface PaletteOptions {
		coolGrey: Partial<Color>
		primaryGradient: Partial<Color>
		yellow: Partial<Color>
	}

	interface TypographyVariants {
		xxSmallRegular: React.CSSProperties
		xxSmallMedium: React.CSSProperties
		xSmallRegular: React.CSSProperties
		xSmallMedium: React.CSSProperties
		smallRegular: React.CSSProperties
		smallMedium: React.CSSProperties
		smallSemiBold: React.CSSProperties
		baseRegular: React.CSSProperties
		baseMedium: React.CSSProperties
		baseSemiBold: React.CSSProperties
		mediumRegular: React.CSSProperties
		mediumMedium: React.CSSProperties
		mediumSemiBold: React.CSSProperties
		largeRegular: React.CSSProperties
		largeMedium: React.CSSProperties
		largeSemiBold: React.CSSProperties
		xLargeRegular: React.CSSProperties
		xLargeMedium: React.CSSProperties
		xLargeSemiBold: React.CSSProperties
		xxLargeRegular: React.CSSProperties
		xxLargeMedium: React.CSSProperties
		xxLargeSemiBold: React.CSSProperties
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		xxSmallRegular: React.CSSProperties
		xxSmallMedium: React.CSSProperties
		xSmallRegular: React.CSSProperties
		xSmallMedium: React.CSSProperties
		smallRegular: React.CSSProperties
		smallMedium: React.CSSProperties
		smallSemiBold: React.CSSProperties
		baseRegular: React.CSSProperties
		baseMedium: React.CSSProperties
		baseSemiBold: React.CSSProperties
		mediumRegular: React.CSSProperties
		mediumMedium: React.CSSProperties
		mediumSemiBold: React.CSSProperties
		largeRegular: React.CSSProperties
		largeMedium: React.CSSProperties
		largeSemiBold: React.CSSProperties
		xLargeRegular: React.CSSProperties
		xLargeMedium: React.CSSProperties
		xLargeSemiBold: React.CSSProperties
		xxLargeRegular: React.CSSProperties
		xxLargeMedium: React.CSSProperties
		xxLargeSemiBold: React.CSSProperties
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		xxSmallRegular: true
		xxSmallMedium: true
		xSmallRegular: true
		xSmallMedium: true
		smallRegular: true
		smallMedium: true
		smallSemiBold: true
		baseRegular: true
		baseMedium: true
		baseSemiBold: true
		mediumRegular: true
		mediumMedium: true
		mediumSemiBold: true
		largeRegular: true
		largeMedium: true
		largeSemiBold: true
		xLargeRegular: true
		xLargeMedium: true
		xLargeSemiBold: true
		xxLargeRegular: true
		xxLargeMedium: true
		xxLargeSemiBold: true
	}
}

declare module '@mui/material' {
	interface Color {
		0?: string
		25?: string
	}
}

declare module '@mui/material/styles' {
	interface ThemeOptions {
		mode: 'dark' | 'light'
	}
	interface Theme {
		mode: 'dark' | 'light'
	}
}

export const themeShadows = () => {
	const { darkMode } = useContext(StyleStoreContext)

	return {
		primary:'0px 8px 16px rgba(0, 171, 85, 0.24)',
		info:'0px 8px 16px rgba(24, 144, 255, 0.24)',
		warning:'0px 8px 16px rgba(255, 193, 7, 0.24)',
		error:'0px 8px 16px rgba(255, 72, 66, 0.24)',
		success:'0px 8px 16px rgba(84, 214, 44, 0.24)',
		borderBottom: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24)',
		borderLeft: 'inset 1px 0px 0px rgba(145, 158, 171, 0.24)',
		borderRight: 'inset -1px 0px 0px rgba(145, 158, 171, 0.24)',
		borderTop: 'inset 0px 1px 0px rgba(145, 158, 171, 0.24)',
		navbar: darkMode ? '0px 12px 24px -4px rgba(0, 0, 0, 0.24)' : '0px 12px 24px -4px rgb(145, 158, 171, 0.24)',
		billingCard: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)',
		x1: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
		z1:darkMode ? '0px 1px 2px rgba(0, 0, 0, 0.12)' : '0px 1px 2px rgba(145, 158, 171, 0.12)',
		z8:darkMode ? '0px 8px 16px rgba(0, 0, 0, 0.12)' : '0px 8px 16px rgba(145, 158, 171, 0.12)',
		z12:darkMode ? '0px 12px 24px -4px rgba(0, 0, 0, 0.12)' : '0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
		z16:darkMode ? '0px 16px 32px -4px rgba(0, 0, 0, 0.12)' : '0px 16px 32px -4px rgba(145, 158, 171, 0.12)',
		z20:darkMode ? '0px 20px 40px -4px rgba(0, 0, 0, 0.12)' : '0px 20px 40px -4px rgba(145, 158, 171, 0.12)',
		z24: darkMode ? '0px 24px 48px rgba(0, 0, 0, 0.2)' : '0px 24px 48px rgba(145, 158, 171, 0.2)',
		z28: darkMode ? ' inset -1px 0px 0px rgba(145, 158, 171, 0.24)' : ' inset -1px 0px 0px rgba(145, 158, 171, 0.24)',
		large: '0px 8px 16px rgba(145, 158, 171, 0.24)',
		dropdownShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)',
		md: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
		setupStepShadow: '0px 6px 16px -2px rgba(16, 24, 40, 0.08), 0px 12px 16px -4px rgba(16, 24, 40, 0.16);',
		dropdownWithWindowShadows: '0rem 0rem 0.25rem rgba(0, 0, 0, 0.25)'
	}
}

export const opacityColors = () => {
	const { darkMode } = useContext(StyleStoreContext)
	return {
		z1: darkMode ? '#121212' : '#f6f7f8',
		primary: 'rgba(84, 214, 44, 0.24)',
		primary12: 'rgba(0, 171, 85, 0.12)',
		primary16: 'rgba(0, 171, 85, 0.16)',
		info: 'rgba(24, 144, 255, 0.24)',
		warning12: 'rgba(255, 193, 7, 0.12)',
		warning: 'rgba(255, 193, 7, 0.24)',
		warning48: 'rgba(255, 193, 7, 0.48)',
		error: 'rgba(255, 72, 66, 0.24)',
		error12: 'rgba(255, 72, 66, 0.12)',
		success: 'rgba(84, 214, 44, 0.24)',
		blackShadow: 'rgba(0, 0, 0, 0.24)',
		lightHover: 'rgba(145, 158, 171, 0.08)',
		borderColor: 'rgba(145, 158, 171, 0.32)',
		secondary: 'rgba(51, 102, 255, 0.24)',
		grey300: darkMode ? 'rgba(69, 79, 91, 0.3)' : 'rgba(223, 227, 232, 0.3)'
	}
}

//@ts-ignore
export const layoutVariables = (dimentions) => {
	const { isLT600ScreenWidth } = dimentions
	const topBarHeight = isLT600ScreenWidth ? '3rem' : '4rem'
	return {
		topBarHeight,
		attentionBannerHeight: '3.5rem',
		mainContentHeight: '100vh',
		maxContentHeight: `calc(100vh - ${topBarHeight})`,
		maxLayoutHeight: `calc(100vh - ${topBarHeight})`,
	}
}

export const PlanStateColors = {
	active: {
		50: '#ECFDF3',
		200: '#ABEFC6',
		500: '#17B26A',
		700: '#067647',
	},
	expired: {
		50: '#FFFAEB',
		200: '#FEDF89',
		500: '#F79009',
		700: '#B54708',
	},
	voided: {
		50: '#F9FAFB',
		200: '#EAECF0',
		500: '#667085',
		700: '#344054',
	}
}

export const AccountColors = [
	'#3cb44b',
	'#4363d8',
	'#ffe119',
	'#f58231',
	'#e6194B',
	'#911eb4',
	'#42d4f4',
	'#f032e6',
	'#bfef45',
	'#fabed4',
	'#469990',
	'#dcbeff',
	'#9A6324',
	'#fffac8',
	'#800000',
	'#aaffc3',
	'#808000',
	'#ffd8b1',
	'#000075',
	'#a9a9a9',
]

export const TagColors = [
	'#FF995F',
	'#B61FE3',
	'#4172DF',
	'#06747C',
	'#DF2180',
	'#ED4343'
]

export const purple = '#B61FE3'

export const themeOptions = (mode: 'light' | 'dark'): ThemeOptions => ({
	palette: {
		mode,
		...(mode === 'light' ? {
			primaryGradient: {
				50: '#F6FEFC',
				100: '#F0FDF9',
				200: '#DBFAF0',
				300: '#ABF2D9',
				400: '#72E4C7',
				500: '#17BAA4',
				600: '#09A391',
				700: '#048275',
				800: '#01645C',
				900: '#004244',
			},
			primary: {
				main: '#09A391',
				contrastText: '#fff',
				600:'#09A391',
				700: '#048275',
				800: '#01645C',
				900: '#004244',
			},
			secondary: {
				main: '#637381',
				contrastText: '#F4F6F8'
			},
			coolGrey: {
				0:  '#FCFCFD',
				25: '#F8F9FC',
				50: '#F5F7FA',
				100: '#E4E7EB',
				200: '#CBD2D9',
				300: '#9AA5B1',
				400: '#7B8794',
				500: '#616E7C',
				600: '#52606D',
				700: '#3E4C59',
				800: '#323F4B',
				900: '#1F2933',
			},
			yellow: {
				50:'#FFFAEB',
				100: '#FCEFC7',
				200: '#F8E3A3',
				300: '#F9DA8B',
				400: '#F7D070',
				500: '#E9B949',
				600: '#C99A2E',
				700: '#A27C1A',
				800: '#7C5E10',
				900: '#513C06',
			},
			grey: {
				50: '#F5F7FA',
				100: '#F9FAFB',
				200: '#F4F6F8',
				300: '#DFE3E8',
				400: '#C4CDD5',
				500: '#919EAB',
				600: '#637381',
				700: '#454F5B',
				800: '#212B36',
				900: '#161C24',
				A100: '#F5F7FA',
				A200: '#E4E7EB',
				A400: '#616E7C',
				A700: '#52606D',
			},
			success: {
				light: '#E9FCD4',
				main: '#54D62C',
				contrastText: '#212B36',
			},
			error: {
				main: '#FF4842',
				light: '#FFEEEE',
				contrastText: '#fff'
			},
			info: {
				main: '#1890FF',
				light:'#D0F2FF',
				contrastText: '#fff'
			},
			warning: {
				main: '#FFC107',
				contrastText: '#212B36'
			},
			background: {
				default:'#FCFCFD',
				paper:'#fff'
			},
		} : {
			primaryGradient: {
				900: '#F6FEFC',
				800: '#F0FDF9',
				700: '#DBFAF0',
				600: '#ABF2D9',
				500: '#72E4C7',
				400: '#17BAA4',
				300: '#09A391',
				200: '#048275',
				100: '#01645C',
				50: '#004244',
			},
			primary: {
				main: '#09A391',
				contrastText: '#fff'
			},
			secondary: {
				main: '#637381',
				contrastText: '#fff'
			},
			coolGrey: {
				900: '#FCFCFD',
				800: '#F8F9FC',
				700: '#F5F7FA',
				600: '#E4E7EB',
				500: '#CBD2D9',
				400: '#9AA5B1',
				300: '#7B8794',
				200: '#616E7C',
				100: '#52606D',
				50: '#3E4C59',
				25: '#222C32',
				0: '#1F2933',
			},
			yellow: {
				900:'#FFFAEB',
				800: '#FCEFC7',
				700: '#F8E3A3',
				600: '#F9DA8B',
				500: '#F7D070',
				400: '#E9B949',
				300: '#C99A2E',
				200: '#A27C1A',
				100: '#7C5E10',
				50: '#513C06',
			},
			grey: {
				50: '#151C20',
				100: '#161C24',
				200: '#212B36',
				300: '#454F5B',
				400: '#637381',
				500: '#919EAB',
				600: '#C4CDD5',
				700: '#DFE3E8',
				800: '#F4F6F8',
				900: '#F9FAFB',
				A100: '#161C24',
				A200: '#52606D',
				A400: '#52606D',
				A700: '#E4E7EB',
			},
			success: {
				main: '#54D62C',
				contrastText: '#212B36'
			},
			error: {
				main: '#FF4842',
				contrastText: '#fff'
			},
			info: {
				main: '#1890FF',
				contrastText: '#fff'
			},
			warning: {
				main: '#FFC107',
				contrastText: '#212B36'
			},
			background: {
				default:'#151c20',
				paper:'#2b2f32'
			}
		})
	},
	typography: {
		fontFamily:'Public Sans',
		h1: {
			fontSize: 64,
			fontWeight: 600,
			lineHeight: '80px'
		},
		h2: {
			fontSize: 48,
			fontWeight: 600,
			lineHeight: '64px'
		},
		h3: {
			fontSize: 32,
			fontWeight: 600,
			lineHeight: '48px'
		},
		h4: {
			fontSize: 24,
			fontWeight: 600,
			lineHeight: '36px'
		},
		h5: {
			fontSize: 20,
			fontWeight: 600,
			lineHeight: '30px'
		},
		h6: {
			fontSize: 18,
			fontWeight: 600,
			lineHeight: '28px'
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 600,
			lineHeight: '24px'
		},
		subtitle2: {
			fontSize: 14,
			fontWeight: 700,
			lineHeight: '22px'
		},
		body1: {
			fontSize: 16,
			fontWeight: 400,
			lineHeight: '24px'
		},
		body2: {
			fontSize: 14,
			fontWeight: 400,
			lineHeight: '22px'
		},
		caption: {
			fontSize: 12,
			fontWeight: 400,
			lineHeight: '18px'
		},
		overline: {
			fontSize: 12,
			fontWeight: 700,
			lineHeight: '18px',
			letterSpacing: 1.2
		},
		xxSmallRegular: {
			fontSize: '0.625rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '0.625rem'
		},
		xxSmallMedium: {
			fontSize: '0.625rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '0.625rem'
		},
		xSmallRegular: {
			fontSize: '0.75rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '1.5rem'
		},
		xSmallMedium: {
			fontSize: '0.75rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '1.5rem'
		},
		smallRegular: {
			fontSize: '0.875rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '1.5rem'
		},
		smallMedium: {
			fontSize: '0.875rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '1.5rem'
		},
		smallSemiBold: {
			fontSize: '0.875rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '1.5rem'
		},
		baseRegular: {
			fontSize: '1rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '1.5rem'
		},
		baseMedium: {
			fontSize: '1rem',
			fontStyle: 'normal',
			fontWeight: '500',
			lineHeight: '1.5rem'
		},
		baseSemiBold: {
			fontSize: '1rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '1.5rem'
		},
		mediumRegular: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.125rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '1.5rem'
		},
		mediumMedium: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.125rem',
			fontStyle: 'normal',
			fontWeight: '500',
			lineHeight: '1.5rem'
		},
		mediumSemiBold: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.125rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '1.5rem'
		},
		largeRegular: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.25rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '1.5rem'
		},
		largeMedium: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.25rem',
			fontStyle: 'normal',
			fontWeight: '500',
			lineHeight: '1.5rem'
		},
		largeSemiBold: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.25rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '1.5rem'
		},
		xLargeRegular: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.5rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '2rem'
		},
		xLargeMedium: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.5rem',
			fontStyle: 'normal',
			fontWeight: '500',
			lineHeight: '2rem'
		},
		xLargeSemiBold: {
			fontFeatureSettings: '"case" on',
			fontSize: '1.5rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '2rem'
		},
		xxLargeRegular: {
			fontFeatureSettings: '"case" on',
			fontSize: '2rem',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '2.5rem'
		},
		xxLargeMedium: {
			fontFeatureSettings: '"case" on',
			fontSize: '2rem',
			fontStyle: 'normal',
			fontWeight: '500',
			lineHeight: '2.5rem'
		},
		xxLargeSemiBold: {
			fontFeatureSettings: '"case" on',
			fontSize: '2rem',
			fontStyle: 'normal',
			fontWeight: '600',
			lineHeight: '2.5rem'
		},
	},
	spacing: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48],
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 850,
			lg: 1200,
			xl: 1536,
		},
	},
	mode,
	components: {
		MuiButtonBase: {
			styleOverrides: {
				root: {
					borderRadius: '0.25rem !important',
					textTransform: 'none !important' as 'none',
					boxShadow: 'none !important',
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: '50% !important',
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					'&:-webkit-autofill': {
						WebkitBoxShadow: 'none',
						WebkitTextFillColor: 'theme.text.primary',
						transition: 'background-color 9999s ease-in-out 0s'
					},
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				PopperProps: {
					popperOptions: {
						modifiers: [
							{
								name: 'offset',
								options: { offset: [0, -8] },
							},
						],
					},
				},
			},
		},
		MuiSwitch: {
			defaultProps: {
				// style override to make the switch look
				// like an iOS switch. See here for more details:
				// https://mui.com/material-ui/react-switch/#customization
				sx: (theme) => ({
					width: '2.4rem',
					height: '1.5rem',
					padding: 0,
					'& .MuiSwitch-switchBase': {
						padding: 1,
						margin: 0,
						transitionDuration: '300ms',
						'&.Mui-checked': {
							transform: 'translateX(16px)',
							color: 'coolGrey.0',
							'& + .MuiSwitch-track': {
								backgroundColor: 'primary.main',
								opacity: 1,
								border: 0,
							},
							'&.Mui-disabled + .MuiSwitch-track': {
								opacity: 0.5,
							},
						},
						'&.Mui-focusVisible .MuiSwitch-thumb': {
							color: '#33cf4d',
							border: '6px solid #fff',
						},
						'&.Mui-disabled .MuiSwitch-thumb': {
							color: mode === 'light'
								? theme.palette.grey[100]
								: theme.palette.grey[600],
						},
						'&.Mui-disabled + .MuiSwitch-track': {
							opacity: mode === 'light' ? 0.7 : 0.3,
						},
					},
					'& .MuiSwitch-thumb': {
						boxSizing: 'border-box',
						height: '1rem',
						width: '1rem',
					},
					'& .MuiSwitch-track': {
						borderRadius: 26 / 2,
						backgroundColor: 'coolGrey.200',
						opacity: 1,
						transition: theme.transitions.create(['background-color'], {
							duration: 500,
						}),
					},
				})
			}
		}
	},
})
