import { useContext, useEffect, useState } from 'react'
import { ContextGenerator } from '../Utils/ContextUtils'
import useLocalStorageState from '../Utils/useLocalStorageState'
import { ShopMetaDataContext } from './ShopMetadataStore'

const useUIContext = () => {
	const { shopMetaData } = useContext(ShopMetaDataContext)
	const [navbarTitle, setNavbarTitle] = useState<string>('')
	const [dontShowNoticeAgain, setDontShowNoticeAgain] = useLocalStorageState('dontShowShopNotice', 'false')
	const [showNotice, setShowNotice] = useState(!!shopMetaData?.shopNotice && !(dontShowNoticeAgain === 'true'))

	useEffect(() => {
		setShowNotice(!!shopMetaData?.shopNotice && !(dontShowNoticeAgain === 'true'))
	}, [shopMetaData, dontShowNoticeAgain])

	return {
		navbarTitle,
		setNavbarTitle,
		showNotice,
		setShowNotice,
		dontShowNoticeAgain,
		setDontShowNoticeAgain,
	}
}

export const { context: UIContext, contextMaker: UIContextMaker } = ContextGenerator(useUIContext)
