import { useContext, useState } from 'react'
import { FilterAlt } from '@mui/icons-material'
import { Badge, Drawer, MenuItem, Typography } from '@mui/material'
import Grid from '../Components/Grid'
import IconButton from '../Components/IconButton'
import { ProductStoreContext } from '../Controllers/ProductStore'

export default function CategorySelect() {
	const {
		productCategories,
		searchCategory,
		setSearchCategory
	} = useContext(ProductStoreContext)
	const [drawerExpanded, setDrawerExpanded] = useState(false)

	return (
		<>
			<IconButton
				sx={{ mr:2 }}
				onClick={
					() => {
						setDrawerExpanded(!drawerExpanded)
					}
				}>
				<Badge
					sx={
						{
							'& .MuiBadge-badge': {
								right: '4px',
								top: '3px',
								height: '0.75rem',
								minWidth: '0.5rem',
							},
						}
					}
					invisible={searchCategory === ''}
					badgeContent=''
					color='warning'>
					<FilterAlt />
				</Badge>
			</IconButton>
			<Drawer
				anchor='right'
				open={drawerExpanded}
				onClose={() => setDrawerExpanded(false)}>
				<Grid
					p={4}
					width='80vw'
					flexcolumn
					fullwidth>
					<Typography variant='h6'>
						Categories
					</Typography>
					<MenuItem
						value=''
						sx={
							{
								color: searchCategory === '' ? 'primary.main' : 'text.primary',
							}
						}
						color={searchCategory === '' ? 'primary.main' : 'text.primary'}
						onClick={
							() => {
								setSearchCategory('')
								setDrawerExpanded(false)
							}
						}>
						<em>
							All
						</em>
					</MenuItem>
					{
						productCategories.map((category) => (
							<MenuItem
								sx={
									{
										color: searchCategory === category.id ? 'primary.main' : 'text.primary',
									}
								}
								onClick={
									() => {
										setSearchCategory(category.id)
										setDrawerExpanded(false)
									}
								}
								key={category.name}
								value={category.id}>
								{category.name}
							</MenuItem>
						))
					}
				</Grid>
			</Drawer>
		</>
	)
}