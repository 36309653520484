import { useContext, useEffect } from 'react'
import { useParams } from 'react-router'
import { Box, Typography } from '@mui/material'
import Grid from '../Components/Grid'
import QuantityContols from '../Components/QuantityContols'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { ProductStoreContext } from '../Controllers/ProductStore'
import { UIContext } from '../Controllers/UIContext'
import { formatNumber } from '../Utils/formatNumber'
import { ProductImage } from './Product'


const ProductDetails = () => {
	const { locale } = useContext(LocalizationContext)
	const { products } = useContext(ProductStoreContext)
	const { navbarTitle, setNavbarTitle } = useContext(UIContext)
	const { id } = useParams()
	const selectedProduct = products.find(product => product.id === id)
	const imageUrl = Object.values(selectedProduct?.imageUrls || {}).find(url => url && !url.includes('mmg.whatsapp.net'))

	useEffect(() => {
		if(navbarTitle !== locale.productDetails) {
			setNavbarTitle(locale.productDetails)
		}
	}, [])

	if(!selectedProduct) {
		return (
			<Box>
				<Typography
					variant='subtitle2'
					gutterBottom />
			</Box>
		)
	}

	return (
		<Grid>
			<Grid
				pt={4}
				px={4}
				flexcolumn
				flexbetweencenter
				wrap='nowrap'
				overflow='scroll'
				height='calc(100vh - 6.9rem)'
			>
				<Grid
					fullwidth
					mr={{ xs:0, sm:2 }}>
					<Typography
						textAlign='center'
						variant='subtitle2'
						mb={3}>
						{selectedProduct?.name}
					</Typography>
					<ProductImage
						url={imageUrl}
						height='unset'
						width='100%' />
					<Typography
						mt={4}
						sx={{ color:'grey.500' }}
						maxHeight={{ xs:'calc(100vh - 31rem)', sm:'calc(100vh - 24rem)' }}
						overflow='scroll'
						variant='body2'
						whiteSpace='pre-line'
					>
						{selectedProduct?.description}
					</Typography>
				</Grid>
				<Grid
					mb={4}
					ml={{ xs:0, sm:2 }}
					flexcolumn
					fullwidth>
					<Grid
						flexcolumn
						mb={4}>
						<Grid flexbetweencenter>
							<Grid>
								<Typography
									variant='body2'
									color='primary'>
									{`${selectedProduct.currency} ${formatNumber((selectedProduct.price || 0) / 1000)}`}
								</Typography>
							</Grid>
							<Grid
								flexendcenter
								mr={8}>
								{
									selectedProduct?.stock ? (
										<QuantityContols
											product={selectedProduct}
										/>
									) : (
										<Typography
											variant='baseMedium'
											color='coolGrey.800'>
											Sold Out
										</Typography>
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ProductDetails