import { useContext, useMemo } from 'react'
import { CircularProgress } from '@mui/material'
import Grid from '../Components/Grid'
import { ProductStoreContext, SORT_PRODUCT_MAP } from '../Controllers/ProductStore'
import Product from './Product'

const Products = () => {
	const {
		products,
		loadingFetch,
		hasNoProducts,
		filterProducts
	} = useContext(ProductStoreContext)

	const calculateProductsHeight = () => {
		const productsHeader = document.getElementById('products-header')
		const header = document.getElementById('header')
		const footer = document.getElementById('footer')

		if(productsHeader && header && footer) {
			const productsHeaderHeight = productsHeader.clientHeight
			const headerHeight = header.clientHeight
			const footerHeight = footer.clientHeight

			return `calc(100vh - ${productsHeaderHeight + headerHeight + footerHeight}px - 2rem) `
		}

		return '50vh'
	}

	const productsHeight = useMemo(() => calculateProductsHeight(), [products, location.pathname])
	return (
		<Grid
			p={4}
			pb={0}
			fullwidth>
			<Grid
				container
				fullwidth
				gap={4}
				sx={
					{
						maxHeight: productsHeight,
						overflowY:'scroll',
						overflowX: 'hidden',
					}
				}>
				{
					loadingFetch && (
						<Grid
							flexcenter
							fullwidth
							fullheight>
							<CircularProgress />
						</Grid>
					)
				}
				{
					hasNoProducts && (
						<Grid
							fullheight
							container
							flexcenter>
							No Products Found
						</Grid>
					)
				}
				{
					//sort by updatedAt
					filterProducts(products).sort((a, b) => {
						if(SORT_PRODUCT_MAP.some(prod => prod === a.id)) {
							return SORT_PRODUCT_MAP.indexOf(a.id) - SORT_PRODUCT_MAP.indexOf(b.id)
						} else {
							const aDate = new Date(a.updatedAt)
							const bDate = new Date(b.updatedAt)

							return bDate.getTime() - aDate.getTime()
						}
					}).map((product, i) => (
						<Product
							product={product}
							key={i} />
					))
				}
			</Grid>
		</Grid>
	)
}

export default Products