export default {
	shopUrl: () => 'https://api-im.chatdaddy.tech/products',
	shopMetadataUrl: (id: string) => `https://api-notifications.chatdaddy.tech/shop-metadata?id=${id}`,
	categoryUrl: () => 'https://api-im.chatdaddy.tech/product-categories',
	scraperUrl: (serviceId: string) => `https://api-ecommerce-integrations.chatdaddy.tech/${serviceId}/shop/products`,
	paymentIntegrationUrl: (teamId: string) => `https://api-notifications.chatdaddy.tech/payment-integrations?teamId=${teamId}`,
	paymentSystemUrl: (teamId: string) => `https://api-notifications.chatdaddy.tech/payment-systems?teamId=${teamId}`,
	sendOtp: () => 'https://api-teams.chatdaddy.tech/otp', // payload {"phoneNumber":"+8801969694969"}
	checkoutProduct: () => 'https://api-notifications.chatdaddy.tech/checkout-product', // payload { order: object, teamId: string, trackingId: string, otp: string}
	getCountryFlag: (country: string) => `http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`
}