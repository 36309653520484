import { useContext, useEffect, useState } from 'react'
import { ContentCopy, Link, Reply } from '@mui/icons-material'
import { Box, Fade, MenuItem, Typography } from '@mui/material'
import Grid from '../Components/Grid'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import { UIContext } from '../Controllers/UIContext'
import { themeShadows } from '../theme'

function encodeURLParameter(url: string) {
	return url.split('&').map(part => encodeURIComponent(part)).join('%26')
}

const Share = () => {
	const { locale } = useContext(LocalizationContext)
	const { urlPrefix } = useContext(ShopMetaDataContext)
	const [copied, setCopied] = useState(false)
	const { navbarTitle, setNavbarTitle } = useContext(UIContext)

	useEffect(() => {
		if(navbarTitle !== locale.catalogueLink) {
			setNavbarTitle(locale.catalogueLink)
		}
	}, [])

	useEffect(() => {
		if(copied) {
			setTimeout(() => {
				setCopied(false)
			}, 750)
		}
	}, [copied])
	return (
		<Grid
			flexcolumn
			fullheight>
			<Grid
				boxShadow={themeShadows().borderBottom}
				flexcolumn
				p={4}>
				<Grid flexstartcenter>
					<Grid
						flexcenter
						sx={
							{
								backgroundColor: 'primary.main',
								color: 'white',
								borderRadius: '50%',
								p: 4,
								width: 'fit-content',
							}
						}>
						<Link sx={{ color:'white' }} />
					</Grid>
					<Typography
						ml={4}
						sx={{ wordBreak:'break-all' }}>
						{`${window.location.origin}/${urlPrefix}/products`}
					</Typography>
				</Grid>
			</Grid>
			<MenuItem
				onClick={
					() => {
						const encodedURI = encodeURLParameter(`${window.location.origin}/${urlPrefix}/products`)
						window.open(`https://wa.me/?text=${encodedURI}`)
					}
				}>
				<Grid flexstartcenter>
					<Reply sx={{ transform: 'rotate(180deg)' }} />
					<Typography ml={4}>
						{locale.shareOnWhatsApp}
					</Typography>
				</Grid>
			</MenuItem>
			<MenuItem
				onClick={
					() => {
						const encodedURI = encodeURI(`${window.location.origin}/${urlPrefix}/products`)
						navigator.clipboard.writeText(encodedURI)
						setCopied(true)
					}
				}>
				<Grid flexstartcenter>
					<ContentCopy />
					<Typography ml={4}>
						{locale.copyLink}
					</Typography>
				</Grid>
			</MenuItem>
			<Fade in={copied}>
				<Box position='relative'>
					<Grid
						fullwidth
						fullheight
						flexcenter
						sx={
							{
								backgroundColor: 'rgba(0,0,0,0.5)',
								position: 'absolute',
								top: 0,
								left: 0,
								zIndex: 10,
							}
						}
						p={4}>
						<Typography
							sx={
								{
									color: 'white',
								}
							} >
							{locale.copied}
						</Typography>
					</Grid>
				</Box>
			</Fade>
		</Grid>
	)
}

export default Share