import { PropsWithChildren, useMemo } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions } from './theme'

const ThemeProviderContextMaker = ({ children }: PropsWithChildren<unknown>) => {
	//TODO add darkmode
	const theme = useMemo(() => createTheme(themeOptions('light')), [])

	return (
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	)
}

export default ThemeProviderContextMaker