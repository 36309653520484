import { useContext, useEffect, useMemo } from 'react'
import { Box, styled, Typography } from '@mui/material'
import Grid from '../Components/Grid'
import { PaymentCountrySelect } from '../Components/PaymentCountrySelect'
import { PaymentStoreContext } from '../Controllers/PaymentIntegrationStore'


const PaymentMethodSelect = () => {
	const { paymentIntegrations, setSelectedIntegration, selectedIntegration, country, setCountry, paymentSystems } = useContext(PaymentStoreContext)

	const Image = styled(Box)({
		width: '2.5rem',
		height: '100%'
	})

	const PaymentIntegrationContainer = styled(Grid)({
		width:'fit-content',
		border: '1px solid',
		borderRadius: '.5rem',
		padding: '1rem',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	})


	const setOfPaymentIntegrations = useMemo(() => {
		const ids = new Set(paymentIntegrations?.[country]?.map((integration) => integration.paymentSystemId))
		const pay = paymentSystems?.filter((system) => ids.has(system.id))

		return pay
	}, [paymentIntegrations, country])

	useEffect(() => {
		setCountry('all')
	}, [])
	return (
		<Grid
			flexcolumn
			fullwidth
			alignItems='center'
			gap={4}>
			<Grid
				mt={4}
				fullwidth
				flexendcenter>
				<PaymentCountrySelect
					currentCountry={country}
					setCurrentCountry={(val) => setCountry(val || 'all')} />
			</Grid>
			<Grid
				gap={6}
				display='flex'
				container
				alignItems='center'
				fullwidth
				justifyContent='center'
			>
				{
					(setOfPaymentIntegrations)?.map((method) => (
						<PaymentIntegrationContainer
							container
							key={method.id}
							sx={
								{
									borderColor: selectedIntegration?.id === method.id ? 'info.main' : 'grey.300',
									backgroundColor: selectedIntegration?.id === method.id ? 'info.light' : 'grey.100',
								}
							}
							onClick={() => setSelectedIntegration(method.id === selectedIntegration?.id ? undefined : method)}
						>
							<Grid
								item
								container
								flexcenter
								direction='column'
								gap={1}
							>
								<Image
									component='img'
									sx={
										{
											objectFit:'contain',
											height:'2.5rem',
											width:'2.5rem'
										}
									}
									// @ts-ignore
									src={method.logoImageUrl}
									alt='img-logo'
								/>
								<Typography
									align='center'
									noWrap
									variant='body2'
									sx={{ textOverflow:'ellipsis', width:'4rem' }}
								>
									{method.name}
								</Typography>
							</Grid>
						</PaymentIntegrationContainer>
					))
				}
			</Grid>
		</Grid>
	)
}

export default PaymentMethodSelect