import React from 'react'
import { IconButton as MUIIconButton, IconButtonProps, Tooltip } from '@mui/material'

export type ButtonProps = {
	tooltip?: string
} & IconButtonProps
const IconButton = (props: React.PropsWithChildren<ButtonProps>) => {
	return (
		<MUIIconButton
			{...props}
			sx={
				{
					width: '1.8rem',
					height: '1.8rem',
					...props.sx
				}
			}>
			{
				!!props.tooltip ? (
					<Tooltip title={props.tooltip}>
						<span>
							{props.children}
						</span>
					</Tooltip>
				) : props.children
			}
		</MUIIconButton>
	)
}

export default IconButton