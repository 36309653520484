import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ArrowBack, HomeOutlined, Info, Link, ShoppingCartOutlined, } from '@mui/icons-material'
import { Badge, BadgeProps, styled, Typography, useTheme } from '@mui/material'
import Grid, { GridProps } from '../Components/Grid'
import IconButton from '../Components/IconButton'
import { CartStoreContext } from '../Controllers/CartStore'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { ProductStoreContext } from '../Controllers/ProductStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import { UIContext } from '../Controllers/UIContext'
import CategorySelect from '../Products/CategorySelect'
import Search from '../Products/Search'
import WhatsappShop from './../Images/whatsappshop.png'

const StyledBadge = styled(Badge)<BadgeProps>(({ }) => ({
	'& .MuiBadge-badge': {
		right: -3,
		color: 'white',
		height: '1rem',
		minWidth: '1rem',
	},
}))

export const NavigationButtons = ({ disableMaxWidth, alternativeGridProps }: {
	disableMaxWidth?: boolean
	alternativeGridProps?: GridProps
}) => {
	const { cart } = useContext(CartStoreContext)
	const navigate = useNavigate()
	const { urlPrefix } = useContext(ShopMetaDataContext)

	const currentScreen = () => {
		const path = window.location.pathname
		if(path.includes('/shop/products')) {
			return 'home'
		}

		if(path.includes('/shop/cart') || path.includes('/shop/checkout')) {
			return 'cart'
		}

		if(path.includes('/shop/share')) {
			return 'share'
		}

		return 'home'
	}

	return (
		<Grid
			flexbetweencenter
			mr={4}
			width={disableMaxWidth ? '100%' : '15rem'}
			{...alternativeGridProps}>
			<IconButton
				onClick={() => navigate(`/${urlPrefix}/products`)}>
				<HomeOutlined color={currentScreen() === 'home' ? 'primary' : 'inherit'} />
			</IconButton>
			<IconButton
				onClick={() => navigate(`/${urlPrefix}/cart`)}>
				<StyledBadge
					badgeContent={cart.reduce((acc, item) => acc + item.quantity, 0)}
					color='primary'>
					<ShoppingCartOutlined color={currentScreen() === 'cart' ? 'primary' : 'inherit'} />
				</StyledBadge>
			</IconButton>
			<IconButton
				onClick={() => navigate(`/${urlPrefix}/share`)}>
				<Link color={currentScreen() === 'share' ? 'info' : 'inherit'} />
			</IconButton>
		</Grid>
	)
}

const NavBar = ({ }) => {
	const { locale } = useContext(LocalizationContext)
	const theme = useTheme()
	const { searchString, setSearchString } = useContext(ProductStoreContext)
	const { setShowNotice } = useContext(UIContext)
	const { urlPrefix } = useContext(ShopMetaDataContext)
	const { navbarTitle } = useContext(UIContext)
	const navigate = useNavigate()
	const location = useLocation()
	const onSJC111 = window.location.hostname === 'www.sjc111.com'
	const content = location.pathname.includes('/products') ? (
		<Grid
			id='header'
			flexbetweencenter
			fullwidth>
			<Grid flexstartcenter>
				<img
					src={WhatsappShop}
					style={{ margin: '0 .5rem' }} />
				<Typography variant='baseMedium'>
					{!!onSJC111 ? 'SJC 111th Shop' : `${locale.whatsapp} ${locale.shop}`}
				</Typography>
			</Grid>
			<Grid
				maxWidth='50%'
				flexstartcenter
				mr={2}>
				{/* <LanguagePicker /> */}
				<Info
					sx={{ mr:2 }}
					onClick={() => setShowNotice(true)}
					color='primary' />
				<CategorySelect />
				<Search
					searchString={searchString}
					setSearchString={setSearchString} />
			</Grid>
		</Grid>
	) : (
		<Grid flexbetweencenter>
			<IconButton
				sx={{ mx: 2 }}
				onClick={() => navigate(`${urlPrefix}/products`)}
			>
				<ArrowBack />
			</IconButton>
			<Typography variant='subtitle1'>
				{navbarTitle}
			</Typography>
		</Grid>
	)

	return (
		<Grid
			flexstartcenter
			sx={{ backgroundColor:theme.palette.grey[200] }}
			width='100%'
			minHeight='3rem'>
			{content}
		</Grid>
	)
}

export default NavBar