import { createContext, useRef, useState } from 'react'
import { Alert, styled, useTheme } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import errorMessageParse from '../Utils/errorMessageParse'
export type AlertCentral = ReturnType<typeof useAlertCentral>

type AlertType = 'info' | 'error' | 'success' | 'warning'
type AlertVarient = 'filled' | 'outlined' | 'standard' | undefined


export const useAlertCentral = () => {
	const [content, setContent] = useState(undefined as React.ReactNode)
	const [type, setType] = useState<AlertType>('success')
	const [visible, setVisible] = useState<boolean>(false)
	const timeout = useRef(undefined as NodeJS.Timeout | undefined)
	const [alertVarient, setAlertVarient] = useState<AlertVarient>('filled')
	const theme = useTheme()

	const isLT600ScreenWidth = true

	const show = (content: React.ReactNode, timeoutMs: number | undefined, type: AlertType = 'success', varient?: AlertVarient) => {
		setContent(content)
		setVisible(true)
		setType(type)
		varient && setAlertVarient(varient)

		if(timeout.current) {
			clearTimeout(timeout.current)
		}

		timeout.current = timeoutMs ? setTimeout(hide, timeoutMs) : undefined
	}

	const hide = () => setVisible(false)

	const StyledAlert = styled(Alert)({
		'&.MuiAlert-filledSuccess': {
			background: theme.palette.primary.main,
			'& .MuiAlert-icon': {
				color: theme.palette.primary.contrastText
			},
			'& .MuiAlert-message': {
				color: theme.palette.primary.contrastText
			},
			'& .MuiAlert-action': {
				color: theme.palette.primary.contrastText
			}
		},
	})

	return {
		/**
         * Show an alert
         * @param {string} content the text to show
         * @param {number} timeout how many ms to show the alert for (set to null for infinite)
				 * @param {AlertVarient} varient the varient of alert which includes  'filled' | 'outlined' | 'standard'
         */
		show: (content: React.ReactNode, timeoutMs?: number, variant?: AlertVarient) => show(content, timeoutMs, 'success', variant)
		,
		/**
         * Show an error alert
         * @param {string} content the text to show
         * @param {number} timeout how many ms to show the alert for (set to null for infinite)
				 * @param {AlertVarient} varient the varient of alert which includes  'filled' | 'outlined' | 'standard'
         */
		error: (content: React.ReactNode | Error, timeoutMs?: number, variant?: AlertVarient) => {
			if(content instanceof Error) {
				content = errorMessageParse(content)
			}

			show(content, timeoutMs, 'error', variant)
		},
		info: (content: React.ReactNode, timeoutMs?: number, variant?: AlertVarient) => show(content, timeoutMs, 'info', variant),
		warning: (content: React.ReactNode, timeoutMs?: number, variant?: AlertVarient) => show(content, timeoutMs, 'warning', variant)
		,
		/** Hide the alert */
		hide,
		alert: (
			<Collapse
				sx={{ position: 'fixed', bottom: '4rem', right: '1.5rem', left: isLT600ScreenWidth ? '1.5rem' : 'unset', zIndex: 100000, }}
				in={visible}>
				<StyledAlert
					sx={{ borderRadius: '.5rem' }}
					variant={alertVarient || 'filled'}
					onClose={hide}
					severity={type}
				>
					{content}
				</StyledAlert>
			</Collapse>
		),
	}
}

export const AlertCentralContext = createContext(undefined as unknown as AlertCentral)
export const AlertCentralContextMaker = ({ children }: { children: React.ReactNode }) => {
	const central = useAlertCentral()
	return (
		<AlertCentralContext.Provider value={central}>
			{central.alert}
			{children}
		</AlertCentralContext.Provider>
	)
}