import { forwardRef, Ref, useContext } from 'react'
import { styled, TextField, TextFieldProps, useTheme } from '@mui/material'
import { LocalizationContext } from '../Controllers/LocalizationStore'

const CustomTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'rgba(145, 158, 171, 0.32)',
			borderRadius: '0.5rem'
		},
	},
})

const MuiPhoneInput = (props: JSX.IntrinsicAttributes & TextFieldProps, ref: Ref<unknown> | undefined) => {
	const theme = useTheme()
	const { locale } = useContext(LocalizationContext)

	return (
		<CustomTextField
			{...props}
			sx={
				{
					marginBottom: theme.spacing(5),
					height: '3.5rem',
				}
			}
			inputRef={ref}
			fullWidth
			InputLabelProps={
				{
					shrink: true,
				}
			}
			label={locale.mobileNumber}
			variant='outlined'
			name='phone'
		/>
	)
}

export default forwardRef(MuiPhoneInput)