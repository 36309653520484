import { Typography } from '@mui/material'
import Grid from '../Components/Grid'


export default () => {
	return (
		<Grid>
			<Typography>
				Payments Page
			</Typography>
		</Grid>
	)
}