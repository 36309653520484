import { useContext } from 'react'
import { Img } from 'react-image'
import { useNavigate } from 'react-router'
import { PlatformProduct } from '@chatdaddy/client'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import Grid from '../Components/Grid'
import QuantityContols from '../Components/QuantityContols'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import PlaceholderImage from '../Images/placeholder.png'

export const ProductImage = ({ url:imgUrl, width = '8rem', height = '8rem' }: {
	url?: string
	width?: string
	height?: string
}) => {
	const theme = useTheme()

	return (
		<Img
			src={[imgUrl, PlaceholderImage]}
			style={
				{
					borderRadius: '1rem',
					width,
					minWidth: width,
					backgroundColor: theme.palette.grey[200],
				}
			}
			loader={
				<Grid
					width={width}
					height={height}
					flexcenter>
					<CircularProgress
						size='2rem' />
				</Grid>
			}
			loading='lazy'
		/>
	)
}

type ProductProps = {
    product: PlatformProduct
}
const Product = ({ product }: ProductProps) => {
	const navigate = useNavigate()
	const { urlPrefix } = useContext(ShopMetaDataContext)
	const imageUrl = Object.values(product?.imageUrls || {}).find(url => url && !url.includes('mmg.whatsapp.net'))
	const theme = useTheme()

	return (
		<Grid
			fullwidth
			onClick={() => navigate(`/${urlPrefix}/productDetails/${product.id}`)}
			py={2}
			flexbetweencenter
			sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}`, cursor:'pointer' }}>
			<Grid flexstartcenter>
				<ProductImage
					url={imageUrl}
					width='5rem'
					height='5rem' />
				<Box
					pl={3}
					display='flex'
					flexDirection='column'>
					<Typography
						variant='subtitle2'
						maxWidth='60vw'>
						{product.name}
					</Typography>
					<Typography
						variant='caption'
						color={theme.palette.text.disabled}>
						{`${product.currency} ${((product.price || 0) / (1000))}`}
					</Typography>
					<Typography
						variant='caption'
						color={theme.palette.text.primary}>
						{product.categories?.length ? product.categories?.[0]?.name : ''}
					</Typography>
				</Box>
			</Grid>
			<Grid>
				{
					product?.stock ? (
						<QuantityContols
							product={product}
						/>
					) : (
						<Typography
							variant='baseMedium'
							color='coolGrey.800'>
							Sold Out
						</Typography>
					)
				}
			</Grid>
		</Grid>
	)
}

export default Product