import React from 'react'
import countries from 'i18n-iso-countries'
import { AlertCentralContextMaker } from './Controllers/Alert'
import { CartStoreContextMaker } from './Controllers/CartStore'
import { CheckoutStoreContextMaker } from './Controllers/CheckoutStore'
import { LocalizationContextMaker } from './Controllers/LocalizationStore'
import { PaymentStoreContextMaker } from './Controllers/PaymentIntegrationStore'
import { ProductStoreContextMaker } from './Controllers/ProductStore'
import { ShopMetaDataContextMaker } from './Controllers/ShopMetadataStore'
import { StyleStoreContextMaker } from './Controllers/StyleStore'
import { UIContextMaker } from './Controllers/UIContext'
import Router from './Router'
import ThemeProviderContextMaker from './ThemeProvider'

const App = () => {
	countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
	return (
		<>
			<AlertCentralContextMaker>
				<ThemeProviderContextMaker>
					<LocalizationContextMaker>
						<ShopMetaDataContextMaker>
							<CartStoreContextMaker>
								<ProductStoreContextMaker>
									<PaymentStoreContextMaker>
										<CheckoutStoreContextMaker>
											<StyleStoreContextMaker>
												<UIContextMaker>
													<Router />
												</UIContextMaker>
											</StyleStoreContextMaker>
										</CheckoutStoreContextMaker>
									</PaymentStoreContextMaker>
								</ProductStoreContextMaker>
							</CartStoreContextMaker>
						</ShopMetaDataContextMaker>
					</LocalizationContextMaker>
				</ThemeProviderContextMaker>
			</AlertCentralContextMaker>
		</>
	)
}

export default App