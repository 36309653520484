import { useContext } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import Grid from '../Components/Grid'
import QuantityContols from '../Components/QuantityContols'
import { CartItem as CartItemType } from '../Controllers/CartStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import { ProductImage } from '../Products/Product'

const CartItem = ({ cartItem }: {cartItem: CartItemType}) => {
	const theme = useTheme()
	const product = cartItem.item
	const imageUrl = Object.values(product.imageUrls).find(url => url && !url.includes('mmg.whatsapp.net'))
	const { teamId, isTotalDemoShop, shopName } = useContext(ShopMetaDataContext)
	const dividePrice = teamId || isTotalDemoShop || shopName
	const price = (product.price || 0) / (dividePrice ? 1000 : 1)
	return (
		<Grid
			py={2}
			flexbetweencenter
			sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
			<Grid flexstartcenter>
				<ProductImage
					url={imageUrl}
					width='5rem'
					height='5rem' />
				<Box
					pl={3}
					display='flex'
					flexDirection='column'>
					<Typography
						noWrap
						variant='subtitle2'
						maxWidth='9.375rem'
					>
						{product.name}
					</Typography>
					<Typography
						variant='caption'
						color={theme.palette.text.disabled}>
						{`${product.currency} ${price} X ${cartItem.quantity}`}
					</Typography>
					<Typography
						variant='caption'
						color={theme.palette.text.primary}>
						{`${product.currency} ${price * cartItem.quantity}`}
					</Typography>
				</Box>
			</Grid>
			<Grid
				width='8rem'
				flexbetweencenter>
				<QuantityContols
					product={product}
				/>
			</Grid>
		</Grid>
	)
}

export default CartItem