import { MouseEvent, useContext, useEffect, useRef, useState } from 'react'
import { PlatformProduct } from '@chatdaddy/client'
import { Add, Remove } from '@mui/icons-material'
import { Box, Fade, Slide, Typography } from '@mui/material'
import IconButton from '../Components/IconButton'
import { CartStoreContext } from '../Controllers/CartStore'
import Grid from './Grid'

const QuantityContols = ({ product, forceShowButtons }: { product: PlatformProduct, forceShowButtons?: boolean}) => {
	const { cart, addToCart, removeFromCart } = useContext(CartStoreContext)
	const [quantity, setQuantity] = useState(cart.find((cartItem) => cartItem.item.id === product?.id)?.quantity || 0)
	const [showJustNumber, setShowJustNumber] = useState(!!quantity)
	const [startCountDown, setStartCountDown] = useState(false)
	const timeoutRef = useRef<NodeJS.Timeout | null>(null)

	const hideQuantity = () => {
		setShowJustNumber(true)
		setStartCountDown(false)
	}

	useEffect(() => {
		if(quantity === 0 && showJustNumber) {
			setShowJustNumber(false)
		}
	}, [quantity, showJustNumber])

	useEffect(() => {
		if(startCountDown) {
			timeoutRef.current = setTimeout(() => {
				hideQuantity() // Call hideQuantity to set showJustNumber to true
			}, 1500)
		} else {
			clearTimeout(timeoutRef.current!)
		}

		return () => {
			clearTimeout(timeoutRef.current!)
		}
	}, [startCountDown])

	const handleAddClick = (e: MouseEvent) => {
		e.stopPropagation()
		setQuantity(quantity + 1)
		addToCart(product)
		setStartCountDown(true)
	}

	const handleRemoveClick = (e: MouseEvent) => {
		e.stopPropagation()

		if(quantity > 0) {
			setQuantity(quantity - 1)
			removeFromCart(product)
			setStartCountDown(true)
		}
	}

	const showNumber = (!forceShowButtons && forceShowButtons !== undefined) || showJustNumber

	return (
		<Grid
			onClick={(e) => e.stopPropagation()}
			fullheight
			fullwidth
			flexcenter
		>
			<Grid
				position='relative'
				minWidth='5rem'
				fullheight
				flexendcenter>
				<Grid
					fullheight
					flexcenter
					zIndex={showNumber ? 1 : 5}
					position='absolute'>
					<Fade in={!showNumber}>
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'>
							<Fade
								in={quantity > 0 || forceShowButtons}
								timeout={200}>
								<Slide
									direction='left'
									in={quantity > 0 || !showNumber}>
									<Box
										display='flex'
										alignItems='center'>
										<IconButton
											disabled={quantity === 0}
											onClick={handleRemoveClick}
											sx={
												{
													backgroundColor: 'grey.100',
													overflow:'hidden',
													borderRadius: 1,
												}
											}>
											<Remove />
										</IconButton>
										<Typography mx={2}>
											{quantity}
										</Typography>
									</Box>
								</Slide>
							</Fade>
							<IconButton
								sx={
									{
										backgroundColor: 'grey.100',
										overflow:'hidden',
										borderRadius: 1,
									}
								}
								onClick={handleAddClick}>
								<Add />
							</IconButton>
						</Box>
					</Fade>
				</Grid>
				<Grid
					position='absolute'
					flexendcenter
					fullheight>
					<Fade in={showNumber}>
						<Box
							zIndex={showNumber ? 5 : 1}
							onClick={
								(e) => {
									e.stopPropagation()
									setStartCountDown(true)
									setShowJustNumber(false)
								}
							}
							sx={
								{
									width: '2rem',
									height: '2rem',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: 'primary.main',
									color: 'white',
									borderRadius: 1,
									'&:hover': {
										backgroundColor: 'primary.dark',
									},
									cursor: 'pointer',
								}
							}
						>
							<Typography>
								{quantity}
							</Typography>
						</Box>
					</Fade>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default QuantityContols
