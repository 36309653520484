import { useContext } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import Button from '../Components/Button'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { opacityColors } from '../theme'

type InputSectionAccordianProps = {
    icon: JSX.Element
    title: string
    children: JSX.Element
	passedValidation: boolean
	expanded: boolean
	setExpanded: (expanded: boolean) => void
	goNext?: () => void
}
const InputSectionAccordian = ({ icon, title, children, passedValidation, expanded, setExpanded, goNext }: InputSectionAccordianProps) => {
	const theme = useTheme()
	const { locale } = useContext(LocalizationContext)
	return (
		<Box>
			<Box
				sx={
					{
						backgroundColor: expanded ? opacityColors().primary : 'grey.100',
						cursor:'pointer',
						borderRadius: '.75rem',
						border: `1px solid ${expanded ? theme.palette.primary.main : theme.palette.grey[400]}`,
						// borderLeft: 'none',
						overflow: 'hidden',
					}
				}
				display='flex'
				justifyContent='space-between'
				onClick={() => setExpanded(!expanded)}
				alignItems='center'>
				<Box
					display='flex'
					alignItems='center'
					width='100%'
				>
					<Box
						sx={
							{
								background: passedValidation ? theme.palette.success.main : theme.palette.warning.main,
								width: '.625rem',
								height: '3rem',
								mr: 4,
								borderTopLeftRadius: '.75rem',
								borderBottomLeftRadius: '.75rem'
							}
						} />
					<Box mr={2}>
						{icon}
					</Box>
					<Typography variant='subtitle2'>
						{title}
					</Typography>
				</Box>
				<Box mr={2}>
					{expanded ? <ExpandLess /> : <ExpandMore />}
				</Box>
			</Box>
			{expanded && children}
			{
				expanded && goNext && (
					<Box
						display='flex'
						width='100%'
						justifyContent='flex-end'>
						<Button
							size='fit-content'
							variant='contained'
							onClick={goNext}
							bgColor='primary'>
							{locale.continue}
						</Button>
					</Box>
				)
			}
		</Box>
	)
}

export default InputSectionAccordian