import { useContext } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import Grid from '../Components/Grid'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import Products from '../Products'
import ShopNotice from './ShopNotice'

const Home = () => {
	const { shopMetaData, loading } = useContext(ShopMetaDataContext)

	return (
		<Grid fullheight>
			{
				loading ? (
					<Grid
						flexcenter
						fullheight>
						<CircularProgress />
					</Grid>
				) : (
					<>
						<Grid id='products-header'>
							<ShopNotice />
							<Grid position='relative'>
								<Grid
									container
									flexcenter
									sx={
										{
											height: '25vh',
											background: `url(${shopMetaData?.shopImageUrl})`,
											backgroundSize: '100% 100%',
											backgroundPosition: 'center',
											backgroundRepeat:'no-repeat',
											flexDirection: 'column',
											// backdropFilter: 'blur(10px)',
											// '-webkit-backdrop-filter': 'blur(10px)',
											// '&:after': {
											// 	content: '""',
											// 	position: 'absolute',
											// 	top: 0,
											// 	left: 0,
											// 	width: '100%',
											// 	height: '100%',
											// 	backgroundColor: 'rgba(0,0,0,0.4)'
											// }
										}
									} />
								<Grid
									position='absolute'
									sx={{ top:0 }}
									fullwidth
									fullheight
									flexcenter>
									<Typography
										textAlign='center'
										sx={
											{
												textShadow: '4px 3px 11px rgba(0,0,0,0.4)',
												lineHeight: '3.75rem',
												fontSize: '2rem'
											}
										}
										variant='h2'
										color='white'>
										{/* {shopMetaData?.shopName || 'Demo Shop'} */}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Products />
					</>
				)
			}
		</Grid>
	)

}

export default Home