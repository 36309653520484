import { useTheme } from '@mui/material'
import Grid from '../Components/Grid'
import { NavigationButtons } from './NavBar'

type FooterProps = {
}
const Footer = ({ }: FooterProps) => {
	const theme = useTheme()
	return (
		<Grid
			id='footer'
			width='100%'
			py={4}
			sx={{ backgroundColor:theme.palette.grey[200] }}
			flexaroundcenter
		>
			<NavigationButtons
				disableMaxWidth
				alternativeGridProps={
					{
						flexbetweencenter: false,
						flexaroundcenter: true
					}
				} />
		</Grid>
	)
}

export default Footer