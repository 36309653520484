import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { OrderMessage, OrderSerialiseContext, serialiseOrderMessage } from '@chatdaddy/client'
import { ContactMailOutlined, PaymentOutlined } from '@mui/icons-material'
import { Box, styled, Typography } from '@mui/material'
import Button from '../Components/Button'
import Grid from '../Components/Grid'
import TextField from '../Components/TextField'
import { CartStoreContext } from '../Controllers/CartStore'
import { CheckoutStoreContext } from '../Controllers/CheckoutStore'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { PaymentStoreContext } from '../Controllers/PaymentIntegrationStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import InputSectionAccordian from './InputSectionAccordian'
import OrderSummary from './OrderSummary'
import PaymentMethodSelect from './PaymentMethodSelect'
import Shipping from './Shipping'
import { Screens } from '.'

export const CustomTextField = styled(TextField)(({ theme }) => ({
	border: '1px solid',
	borderColor: theme.palette.grey[300],
	borderRadius: '.5rem'
}))

const CheckoutDetails = ({ }: { setScreen: (value: Screens) => void}) => {
	const { sameAsContact, contact, shippingDetails, setShippingDetails, setContact, resetCheckout, freeShipping } = useContext(CheckoutStoreContext)
	const { shopMetaData, shopName, urlPrefix } = useContext(ShopMetaDataContext)
	const { cart, cartNote, resetCart } = useContext(CartStoreContext)
	const { selectedIntegration, getPaymentIntegrations } = useContext(PaymentStoreContext)
	const { locale } = useContext(LocalizationContext)
	const navigate = useNavigate()
	const [expanded, setExpanded] = useState<'shippingDetails' | 'paymentMethod' | 'contact' | undefined>('contact')

	useEffect(() => {
		getPaymentIntegrations()
	}, [])

	const generateOrderContext = useCallback(() => {
		return {
			shopName:shopMetaData?.shopName || '',
			paymentIntegration:{
				id: selectedIntegration?.currentIntegrations?.[0]?.id! || selectedIntegration?.id!,
				name: selectedIntegration?.name!,
			}
		}
	}, [shopName, selectedIntegration])

	const generateOrderFromCart = useCallback(() => {
		return {
			items:cart.map(({ item, quantity }) => {
				return {
					id: item.id,
					name: item.name,
					quantity,
					price: (item.price || 0) / 1000,
					currency: item.currency || '',
				}
			}),
			remarks: cartNote,
			customer: {
				name:contact.name,
				mobileNumber:contact.mobileNumber,
				shippingAddress: shippingDetails.shippingAddress,
				shippingMethod: shippingDetails.shippingMethod,
				shippingOption: shippingDetails.shippingOption,
				pickupLocation: shippingDetails.pickupLocation,
			},
			deliveryFees: freeShipping ? 0 : shippingDetails.deliveryFees || 0,
			additionalFees: shopMetaData?.additionalFees?.filter(fee => fee.enabled).map(fee => ({ name: fee.name || '', amount: `${fee.amount || 0}` })) || [],
		}
	}, [cart, cartNote, contact, shippingDetails])

	const sendNowLink = useMemo(() => {
		const phone = shopMetaData?.shopPhoneNumber?.[0] === '+' ? shopMetaData.shopPhoneNumber.slice(1) : shopMetaData?.shopPhoneNumber
		const order: OrderMessage = generateOrderFromCart()
		const shopContext: OrderSerialiseContext = generateOrderContext()
		const message = serialiseOrderMessage(order, shopContext)
		console.log(shopMetaData?.shopPhoneNumber)
		const url = new URL(`https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`)
		return url.href
	}, [cart, cartNote, shopMetaData?.shopPhoneNumber, generateOrderFromCart, contact, generateOrderContext, shippingDetails])

	const shippingDetailsValidation = useMemo(() => {
		if(shippingDetails.shippingMethod === 'delivery') {
			return !!shippingDetails.name.length && !!shippingDetails.mobileNumber.length && !!shippingDetails.shippingAddress.length
		} else if(shippingDetails.shippingMethod === 'pickup') {
			return !!shippingDetails.pickupLocation?.length
		} else {
			return true
		}
	}, [shippingDetails])
	const paymentValidation = !!selectedIntegration
	const contactValidation = !!contact.name.length && !!contact.mobileNumber.length

	useEffect(() => {
		if(sameAsContact) {
			setShippingDetails({
				...shippingDetails,
				name: contact.name,
				mobileNumber: contact.mobileNumber
			})
		}
	}, [sameAsContact, contact])

	return (
		<Grid
			flexcolumn
			gap={3}>
			<OrderSummary />
			<InputSectionAccordian
				expanded={expanded === 'contact'}
				setExpanded={() => setExpanded(expanded === 'contact' ? undefined : 'contact')}
				passedValidation={contactValidation}
				icon={<ContactMailOutlined />}
				goNext={() => setExpanded('shippingDetails')}
				title={locale.contact}>
				<Box>
					<CustomTextField
						value={contact.name}
						onChange={(e) => setContact({ ...contact, name: e.target.value })}
						sx={{ mt: 4 }}
						fullWidth
						forceShrink
						label={locale.fullName}
						placeholder={locale.nameHere}
					/>
					<TextField
						value={contact.mobileNumber}
						onChange={(e) => setContact({ ...contact, mobileNumber: e.target.value })}
						fullWidth
						type='phone'
						forceShrink
						label='Whatsapp Phone Number'
						placeholder={locale.inputNumber}
					/>
				</Box>
			</InputSectionAccordian>
			<Shipping
				expanded={expanded}
				setExpanded={setExpanded}
				shippingDetailsValidation={shippingDetailsValidation} />
			<InputSectionAccordian
				expanded={expanded === 'paymentMethod'}
				setExpanded={() => setExpanded(expanded === 'paymentMethod' ? undefined : 'paymentMethod')}
				passedValidation={paymentValidation	}
				icon={<PaymentOutlined />}
				title={locale.paymentMethod}>
				<PaymentMethodSelect />
			</InputSectionAccordian>
			<Button
				variant='contained'
				bgColor='primary'
				size='full'
				disabled={ !shippingDetailsValidation || !contactValidation || !paymentValidation}
				onClick={
					() => {
						window.open(sendNowLink)
						resetCart()
						resetCheckout()
						navigate(`/${urlPrefix}/products`)
					}
				}>
				{locale.placeOrder}
			</Button>

			<Typography
				color='coolGrey.800'
				variant='baseRegular'>
				You will be redirected to Whatsapp, please send the message to confirm your order
			</Typography>
		</Grid>
	)
}

export default CheckoutDetails