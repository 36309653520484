import { useContext } from 'react'
import { PriceChangeOutlined } from '@mui/icons-material'
import { Divider, Typography } from '@mui/material'
import Grid from '../Components/Grid'
import { CartStoreContext } from '../Controllers/CartStore'
import { CheckoutStoreContext } from '../Controllers/CheckoutStore'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { PaymentStoreContext } from '../Controllers/PaymentIntegrationStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'

const OrderSummary = () => {
	const { locale } = useContext(LocalizationContext)
	const { cart } = useContext(CartStoreContext)
	const { teamId, isTotalDemoShop, shopName, shopMetaData } =
    useContext(ShopMetaDataContext)
	const { shippingDetails } = useContext(CheckoutStoreContext)
	const { selectedIntegration } = useContext(PaymentStoreContext)
	const dividePrice = teamId || isTotalDemoShop || shopName

	const cartPrice = cart.reduce(
		(acc, item) => acc + ((item.item.price || 0) / (dividePrice ? 1000 : 1)) * item.quantity,
		0
	)

	const additionalFees =
    shopMetaData?.additionalFees
    	?.filter((fee) => fee.enabled)
    	.reduce((acc, fee) => acc + (fee.amount || 0), 0) || 0
	const deliveryFees = shopMetaData?.freeShippingThreshold && cartPrice > shopMetaData?.freeShippingThreshold ? 0 : Number(shippingDetails?.deliveryFees || 0)
	const grandTotal = cartPrice + additionalFees + deliveryFees

	const deliveryFeesString = deliveryFees ? `${cart[0] ? cart[0].item.currency : ''} ${deliveryFees}` : 'Free'

	return (
		<Grid >
			<Grid
				py={2}
				display='flex'
				alignItems='center'>
				<PriceChangeOutlined sx={{ mr: 2 }} />
				<Typography variant='subtitle2'>
					{locale.orderSummary}
				</Typography>
			</Grid>
			<Grid
				p={4}
				flexcolumn
				gap={3}
				sx={
					{
						backgroundColor: 'info.light',
						border: '1px solid',
						borderColor: 'info.main',
						borderRadius: '.75rem',
					}
				}
			>
				<Grid

					display='flex'
					justifyContent='space-between'>
					<Typography variant='body2'>
						{`${locale.items}`}
					</Typography>
					<Typography variant='body2'>
						{`${cart.reduce((acc, item) => acc + item.quantity, 0)}`}
					</Typography>
				</Grid>
				<Divider sx={{ }} />

				{
					cart.map((item) => (
						<Grid
							key={item.item.id}

							display='flex'
							justifyContent='space-between'
						>
							<Typography
								variant='body2'
								maxWidth='70%'>
								{`${item.item.name} (${item.quantity})`}
							</Typography>
							<Typography variant='body2'>
								{
									`${item.quantity} x ${item.item.currency} ${
										(item.item.price || 0) / (dividePrice ? 1000 : 1)
									}`
								}
							</Typography>
						</Grid>
					))
				}
				<Divider sx={{ }} />
				<Grid

					display='flex'
					justifyContent='space-between'>
					<Typography variant='body2'>
						{`${locale.totalOrder}`}
					</Typography>
					<Typography variant='body2'>
						{`${cart[0] ? cart[0].item.currency : ''} ${cartPrice}`}
					</Typography>
				</Grid>
				{
					shopMetaData?.additionalFees && shopMetaData?.additionalFees?.filter((fee) => fee.enabled)?.length > 0 && (
						<>
							<Divider sx={{ }} />
							{
								shopMetaData?.additionalFees
									?.filter((fee) => fee.enabled)
									.map((fee) => (
										<Grid
											key={fee.name}

											display='flex'
											justifyContent='space-between'
										>
											<Typography variant='body2'>
												{`${fee.name}`}
											</Typography>
											<Typography variant='body2'>
												{`${fee.currency} ${fee.amount || 0}`}
											</Typography>
										</Grid>
									))
							}
						</>
					)
				}
				{
					shippingDetails?.shippingOption && shippingDetails?.deliveryFees !== undefined ? (
						<>
							<Divider sx={{ }} />
							<Grid

								display='flex'
								justifyContent='space-between'>
								<Typography variant='body2'>
									Shipping Fees
								</Typography>
								<Typography variant='body2'>
									{deliveryFeesString}
								</Typography>
							</Grid>
						</>
					) : null
				}
				{
					shippingDetails?.shippingOption && shippingDetails?.deliveryFees !== undefined ? (
						<>
							<Divider sx={{ }} />
							<Grid

								display='flex'
								justifyContent='space-between'>
								<Typography variant='body2'>
									Grand Total
								</Typography>
								<Typography variant='body2'>
									{`${cart[0] ? cart[0].item.currency : ''} ${grandTotal}`}
								</Typography>
							</Grid>
						</>
					) : null
				}
				{
					!!selectedIntegration && (
						<>
							<Divider sx={{ }} />
							<Grid

								display='flex'
								justifyContent='space-between'>
								<Typography variant='body2'>
									Payment Gateway
								</Typography>
								<Typography variant='body2'>
									{selectedIntegration?.name}
								</Typography>
							</Grid>
						</>
					)
				}
			</Grid>
		</Grid>
	)
}

export default OrderSummary
