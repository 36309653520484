import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Cart from '../Cart'
import Checkout from '../Checkout'
import Grid from '../Components/Grid'
import Home from '../Home/Home'
import Payments from '../Payments'
import ProductDetails from '../Products/ProductDetails'
import Share from '../Share'
import Container from './Container'
import Footer from './Footer'
import NavBar from './NavBar'

const AppRouter = () => {
	return (
		<Router>
			<Grid
				flexcenter
				fullheight>
				<Grid
					flexcolumn
					fullheight
					fullwidth
					maxWidth='35rem'
					sx={{ backgroundColor: '#fff' }}
					justifyContent='space-between'>
					<Grid
						fullwidth
						fullheight
						flexcolumn>
						<NavBar />
						<Routes>
							<Route
								id='root'
								path='/:locale/:shopName/:isDemo'
								element={
									<>
										<Container />
									</>
								}>
								<Route
									path='/:locale/:shopName/:isDemo'
									element={<Home />}>
									<Route
										path='/:locale/:shopName/:isDemo/products' />
								</Route>
								<Route
									path='/:locale/:shopName/:isDemo/productDetails/:id'
									element={<ProductDetails />} />
								<Route
									path='/:locale/:shopName/:isDemo/cart'
									element={<Cart />} />
								<Route
									path='/:locale/:shopName/:isDemo/checkout'
									element={<Checkout />} />
								<Route
									path='/:locale/:shopName/:isDemo/share'
									element={<Share />} />
								<Route
									path='/:locale/:shopName/:isDemo/payment'
									element={<Payments />} />
							</Route>
							<Route
								path='*'
								element={<Navigate to='/en/test-shop/demo/products' />} />
						</Routes>
					</Grid>
					<Grid>
						<Footer />
					</Grid>
				</Grid>
			</Grid>
		</Router>
	)
}

export default AppRouter
