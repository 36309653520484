import React, { useEffect, useMemo, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, styled, TextField as MUITextField, TextFieldProps as MuiTextFieldProps, useTheme } from '@mui/material'
import PhoneNumberInput from './PhoneNumberInput'

type TextFieldProps = {
	startIcon?: JSX.Element
	endIcon?: JSX.Element
	size?: 'large' | 'small'
	fieldVarient?: 'standard' | 'outlined' | 'filled'
	forceShrink?: boolean
	height?: string | number
} & Omit<MuiTextFieldProps, 'variant'>

export const CustomTextField = styled(MUITextField)((props) => ({
	'& .MuiOutlinedInput-root': {
		borderRadius: '0.5rem',
		'& fieldset': {
			// border:'none',
			'& legend': {
				width: props.label ? 'auto' : 'unset',
				backgroundColor: props.label ? props.theme.palette.background.paper : 'unset',
				visibility: props.label ? 'visible' : 'hidden',
			}
		},
	},
	'& .MuiInput-underline:before': {
		borderWidth: 0,
		borderBottom: 'none',
	}
}))

const TextField = (props: TextFieldProps) => {
	const [showPassword, setShowPassword] = useState(false)
	const theme = useTheme()
	const type = useMemo(() => {
		if(props.type === 'password') {
			if(showPassword) {
				return 'text'
			} else {
				return 'password'
			}
		} else {
			return props.type
		}
	}, [props.type, showPassword])

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	useEffect(() => {
		props.type === 'password' && setShowPassword(false)
	}, [props.type])
	return (
		props.type !== 'phone' ? (
			<CustomTextField
				{...props}
				variant={props.fieldVarient ? props.fieldVarient : 'outlined'}
				type={type}
				autoComplete={props.type === 'password' ? 'password' : undefined}
				InputLabelProps={
					{
						shrink: props.forceShrink,
					}
				}
				InputProps={
					{
						startAdornment: props.startIcon,
						endAdornment: props.type === 'password' ? (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={handleMouseDownPassword}
									edge='end'
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						) : props.endIcon || null,
						sx: {
							height: props.height ? props.height : 'unset'
						}
					}
				}
				sx={
					{
						marginBottom: theme.spacing(4),
						height: props.height ? props.height : props.size === 'small' ? 40 : 56,
						...(props.sx || {}),
					}
				}
			/>
		)
			: (
				<PhoneNumberInput
					error={props.error}
					phoneNumber={props.value as string}
					initialPhoneNumber={props.value as string}
					setPhoneNumber={
						(value) => props?.onChange && props?.onChange(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						{ target: { value: value || '' } }as any)
					}
				/>
			)
	)
}

export default TextField