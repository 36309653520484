import { Grid as MuiGrid, GridProps as MuiGridProps } from '@mui/material'


export type GridProps = {
    flexcenter?: boolean
    flexstretch?: boolean
	flexstart?: boolean
    flexend?: boolean
    flexstartcenter?: boolean
    flexstartend?: boolean
    flexstartstretch?: boolean
    flexstartbaseline?: boolean
    flexendcenter?: boolean
    flexendstart?: boolean
    flexendstretch?: boolean
    flexendbaseline?: boolean
    flexcenterstart?: boolean
    flexcenterend?: boolean
    flexcenterstretch?: boolean
    flexcenterbaseline?: boolean
    flexbetweenstart?: boolean
    flexbetweencenter?: boolean
    flexbetweenend?: boolean
    flexbetweenstretch?: boolean
    flexbetweenbaseline?: boolean
    flexaroundstart?: boolean
    flexaroundcenter?: boolean
    flexAroundEnd?: boolean
    flexaroundstretch?: boolean
    flexaroundbaseline?: boolean
    flexevenlystart?: boolean
    flexevenlycenter?: boolean
    flexevenlyend?: boolean
    flexevenlystretch?: boolean
    flexevenlybaseline?: boolean
	flexcolumn?: boolean
	fullheight?: boolean
	fullwidth?: boolean
} & MuiGridProps
const Grid = (props: GridProps) => {
	const {
		flexcenter,
		flexstretch,
		flexend,
		flexstart,
		flexstartcenter,
		flexstartend,
		flexstartstretch,
		flexstartbaseline,
		flexendcenter,
		flexendstart,
		flexendstretch,
		flexendbaseline,
		flexcenterstart,
		flexcenterend,
		flexcenterstretch,
		flexcenterbaseline,
		flexbetweenstart,
		flexbetweencenter,
		flexbetweenend,
		flexbetweenstretch,
		flexbetweenbaseline,
		flexaroundstart,
		flexaroundcenter,
		flexAroundEnd,
		flexaroundstretch,
		flexaroundbaseline,
		flexevenlystart,
		flexevenlycenter,
		flexevenlyend,
		flexevenlystretch,
		flexevenlybaseline,
		flexcolumn,
		fullheight,
		fullwidth,
	} = props

	let flexProps = {}
	if(flexstart) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'start',
			justifyContent: 'start',
		}
	} else if(flexcenter) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}
	} else if(flexstretch) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'center',
		}
	} else if(flexend) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		}
	} else if(flexstartcenter) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
		}
	} else if(flexstartend) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'flex-start',
		}
	} else if(flexstartstretch) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'flex-start',
		}
	} else if(flexstartbaseline) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'flex-start',
		}
	} else if(flexendcenter) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		}
	} else if(flexendstart) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'flex-end',
		}
	} else if(flexendstretch) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'flex-end',
		}
	} else if(flexendbaseline) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'flex-end',
		}
	} else if(flexcenterstart) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'center',
		}
	} else if(flexcenterend) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'center',
		}
	} else if(flexcenterstretch) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'center',
		}
	} else if(flexcenterbaseline) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'center',
		}
	} else if(flexbetweenstart) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'space-between',
		}
	} else if(flexbetweencenter) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}
	} else if(flexbetweenend) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'space-between',
		}
	} else if(flexbetweenstretch) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'space-between',
		}
	} else if(flexbetweenbaseline) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'space-between',
		}
	} else if(flexaroundstart) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'space-around',
		}
	} else if(flexaroundcenter) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
		}
	} else if(flexAroundEnd) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'space-around',
		}
	} else if(flexaroundstretch) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'space-around',
		}
	} else if(flexaroundbaseline) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'space-around',
		}
	} else if(flexevenlystart) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'space-evenly',
		}
	} else if(flexevenlycenter) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-evenly',
		}
	} else if(flexevenlyend) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'space-evenly',
		}
	} else if(flexevenlystretch) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'space-evenly',
		}
	} else if(flexevenlybaseline) {
		flexProps = {
			...flexProps,
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'space-evenly',
		}
	}

	if(flexcolumn) {
		flexProps = {
			...flexProps,
			display:'flex',
			flexDirection: 'column'
		}
	}

	if(fullheight) {
		flexProps = {
			...flexProps,
			height: '100%'
		}
	}

	if(fullwidth) {
		flexProps = {
			...flexProps,
			width: '100%'
		}
	}

	return (
		<MuiGrid
			{...flexProps}
			{...props}
		/>
	)
}

export default Grid