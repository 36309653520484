import { useState } from 'react'
import { Close, SearchRounded } from '@mui/icons-material'
import IconButton from '../Components/IconButton'
import TextField from '../Components/TextField'

const Search = ({ searchString, setSearchString }: {
    searchString: string
    setSearchString: (searchString: string) => void
}) => {
	const [showSearch, setShowSearch] = useState(false)

	return (
		<>
			{
				!showSearch ? (
					<IconButton>
						<SearchRounded onClick={() => setShowSearch(!showSearch)} />
					</IconButton>
				) : (
					<TextField
						startIcon={<SearchRounded sx={{ mr: 2, color: 'grey.500' }} />}
						onChange={
							(e) => {
								setSearchString(e.target.value)
							}
						}
						value={searchString}
						sx={
							{
								width: '100%',
								mb:0,
								'& .MuiInputBase-root': {
									px:1
								}
							}
						}
						endIcon={
							<IconButton>
								<Close
									onClick={
										() => {
											setSearchString('')
											setShowSearch(false)
										}
									} />
							</IconButton>
						}
						height='2rem'
						placeholder='Search...' />
				)
			}
		</>
	)
}

export default Search