import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { UIContext } from '../Controllers/UIContext'
import CheckoutDetails from './CheckoutDetails'
// import Completion from './Completion'
// import ConfirmOtp from './ConfirmOtp'
// import PaymentMethod from './PaymentMethod'
// import PaymentScreenShots from './PaymentScreenShots'

export type Screens = 'details' | 'payment' | 'uploadProof' | 'otp' | 'completion'
const Checkout = () => {
	const { locale } = useContext(LocalizationContext)
	const [screen, setScreen] = useState<Screens>('details')
	const { navbarTitle, setNavbarTitle } = useContext(UIContext)
	const title = screen === 'uploadProof' ? locale.paymentScreenshots : screen === 'completion' ? '' : locale.checkout

	useEffect(() => {
		if(navbarTitle !== title) {
			setNavbarTitle(title)
		}
	}, [title])
	return (
		<Box>
			{/* <GoogleReCaptchaProvider reCaptchaKey='6LewZwElAAAAABe3G5G_68ZRo6kVzFehPk8DsM71'> */}
			<Box
				px={4}
				py={0}
				pt={4}
				overflow='scroll'
				sx={{ overflowX: 'hidden' }}
				height='calc(100vh - 6.9rem)'
			>
				{screen === 'details' && <CheckoutDetails setScreen={setScreen} />}
				{/* {
						screen === 'payment' && (
							<PaymentMethod
								screen={screen}
								setScreen={setScreen}
							/>
						)
					}
					{screen === 'otp' && <ConfirmOtp setScreen={setScreen} />}
					{screen === 'uploadProof' && <PaymentScreenShots setScreen={setScreen} />}
					{screen === 'completion' && <Completion />} */}
			</Box>
			{/* </GoogleReCaptchaProvider> */}
		</Box>
	)
}

export default Checkout