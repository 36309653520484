/* eslint-disable max-depth */
import { useContext, useEffect, useState } from 'react'
import { PaymentSystem } from '@chatdaddy/client'
import axios from 'axios'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { ContextGenerator } from '../Utils/ContextUtils'
import urls from '../Utils/urls'
import { AlertCentralContext } from './Alert'
import { ShopMetaDataContext } from './ShopMetadataStore'
interface PaymentIntegration {
	id: string
	teamId: string
	enabled: boolean
	createdAt: string
	updatedAt: string
	qrCodeUrl?: string
	paymentSystemId: string
	logoImageUrl?: string
	name: string
}

type IntegrationsType = { [key: string]: PaymentIntegration[]}

const usePaymentStore = () => {
	const { userCountry } = useContext(LocalizationContext)
	const { shopMetaData } = useContext(ShopMetaDataContext)
	const alertCentral = useContext(AlertCentralContext)

	const [paymentIntegrations, setPaymentIntegrations] = useState<IntegrationsType>({})
	const [paymentSystems, setPaymentSystems] = useState<PaymentSystem[]>([])
	const [selectedIntegration, setSelectedIntegration] = useState<PaymentSystem>()
	const [country, setCountry] = useState(userCountry)

	const resetPayment = () => {
		setSelectedIntegration(undefined)
	}

	useEffect(() => {
		setSelectedIntegration(undefined)
	}, [country])

	const getPaymentIntegrations = async() => {
		const { data: { paymentSystems } } = await axios.get(urls.paymentSystemUrl(shopMetaData?.id!))
		setPaymentSystems(paymentSystems)
		if(shopMetaData?.id) {
			try {
				const { data: { integrations:paymentIntegrations } } = await axios.get(urls.paymentIntegrationUrl(shopMetaData?.id))
				const integrations = paymentIntegrations.filter((integration: PaymentIntegration) => integration.enabled)
				if(integrations.length && paymentSystems.length) {
					const cpyIntegrations = {} as IntegrationsType

					for(const integration of integrations) {
						for(const paymentSystem of paymentSystems) {
							if(integration.paymentSystemId === paymentSystem.id) {
								const { country } = paymentSystem
								const cpyIntegration = {
									...integration,
									logoImageUrl: paymentSystem.logoImageUrl,
									name: paymentSystem.name
								}

								if(!cpyIntegrations[country]) {
									cpyIntegrations[country] = [cpyIntegration]
								} else {
									cpyIntegrations[country].push(cpyIntegration)
								}

								if(!cpyIntegrations['all']) {
									cpyIntegrations['all'] = [cpyIntegration]
								} else {
									cpyIntegrations['all'].push(cpyIntegration)
								}

								break
							}
						}
					}

					setPaymentIntegrations(cpyIntegrations)
				}
			} catch(e) {
				alertCentral.error(e, 2000)
			}
		}
	}


	useEffect(() => {
		getPaymentIntegrations()
	}, [shopMetaData?.id])

	return {
		country,
		setCountry,
		paymentIntegrations,
		selectedIntegration,
		setSelectedIntegration,
		resetPayment,
		getPaymentIntegrations,
		paymentSystems
	}
}

export const { context: PaymentStoreContext, contextMaker: PaymentStoreContextMaker } = ContextGenerator(usePaymentStore)
