import { useEffect, useState } from 'react'
import axios from 'axios'
import LOCALES from '../locales.json'
import { ContextGenerator } from '../Utils/ContextUtils'
import useLocalStorageState from '../Utils/useLocalStorageState'

export type Language = keyof typeof LOCALES
export type LocaleKey = keyof typeof LOCALES['English']
export const LANGUAGE_REGION_MAP: { [_: string]: { Language: string, CountryCode: string } } = {
	'af': { Language: 'Afrikaans', CountryCode: 'ZA' },
	'sq': { Language: 'Albanian', CountryCode: 'AL' },
	'am': { Language: 'Amharic', CountryCode: 'ET' },
	'ar': { Language: 'Arabic', CountryCode: 'SA' },
	'hy': { Language: 'Armenian', CountryCode: 'AM' },
	'az': { Language: 'Azerbaijani', CountryCode: 'AZ' },
	'bn': { Language: 'Bengali', CountryCode: 'BD' },
	'bs': { Language: 'Bosnian', CountryCode: 'BA' },
	'bg': { Language: 'Bulgarian', CountryCode: 'BG' },
	'ca': { Language: 'Catalan', CountryCode: 'ES' },
	'zh': { Language: 'Chinese (Simplified)', CountryCode: 'CN' },
	'zh-TW': { Language: 'Chinese (Traditional)', CountryCode: 'TW' },
	'hr': { Language: 'Croatian', CountryCode: 'HR' },
	'cs': { Language: 'Czech', CountryCode: 'CZ' },
	'da': { Language: 'Danish', CountryCode: 'DK' },
	'fa-AF': { Language: 'Dari', CountryCode: 'AF' },
	'nl': { Language: 'Dutch', CountryCode: 'NL' },
	'en': { Language: 'English', CountryCode: 'GB' },
	'et': { Language: 'Estonian', CountryCode: 'EE' },
	'fa': { Language: 'Farsi (Persian)', CountryCode: 'IR' },
	'tl': { Language: 'Filipino, Tagalog', CountryCode: 'PH' },
	'fi': { Language: 'Finnish', CountryCode: 'FI' },
	'fr': { Language: 'French', CountryCode: 'FR' },
	'fr-CA': { Language: 'French (Canada)', CountryCode: 'CA' },
	'ka': { Language: 'Georgian', CountryCode: 'GE' },
	'de': { Language: 'German', CountryCode: 'DE' },
	'el': { Language: 'Greek', CountryCode: 'GR' },
	'gu': { Language: 'Gujarati', CountryCode: 'IN' },
	'ht': { Language: 'Haitian Creole', CountryCode: 'HT' },
	'ha': { Language: 'Hausa', CountryCode: 'NG' },
	'he': { Language: 'Hebrew', CountryCode: 'IL' },
	'hi': { Language: 'Hindi', CountryCode: 'IN' },
	'hu': { Language: 'Hungarian', CountryCode: 'HU' },
	'is': { Language: 'Icelandic', CountryCode: 'IS' },
	'id': { Language: 'Indonesian', CountryCode: 'ID' },
	'ga': { Language: 'Irish', CountryCode: 'IE' },
	'it': { Language: 'Italian', CountryCode: 'IT' },
	'ja': { Language: 'Japanese', CountryCode: 'JP' },
	'kn': { Language: 'Kannada', CountryCode: 'IN' },
	'kk': { Language: 'Kazakh', CountryCode: 'KZ' },
	'ko': { Language: 'Korean', CountryCode: 'KR' },
	'lv': { Language: 'Latvian', CountryCode: 'LV' },
	'lt': { Language: 'Lithuanian', CountryCode: 'LT' },
	'mk': { Language: 'Macedonian', CountryCode: 'MK' },
	'ms': { Language: 'Malay', CountryCode: 'MY' },
	'ml': { Language: 'Malayalam', CountryCode: 'IN' },
	'mt': { Language: 'Maltese', CountryCode: 'MT' },
	'mr': { Language: 'Marathi', CountryCode: 'IN' },
	'mn': { Language: 'Mongolian', CountryCode: 'MN' },
	'no': { Language: 'Norwegian (Bokmål)', CountryCode: 'NO' },
	'ps': { Language: 'Pashto', CountryCode: 'AF' },
	'pl': { Language: 'Polish', CountryCode: 'PL' },
	'pt': { Language: 'Portuguese (Brazil)', CountryCode: 'BR' },
	'pt-PT': { Language: 'Portuguese (Portugal)', CountryCode: 'PT' },
	'pa': { Language: 'Punjabi', CountryCode: 'IN' },
	'ro': { Language: 'Romanian', CountryCode: 'RO' },
	'ru': { Language: 'Russian', CountryCode: 'RU' },
	'sr': { Language: 'Serbian', CountryCode: 'RS' },
	'si': { Language: 'Sinhala', CountryCode: 'LK' },
	'sk': { Language: 'Slovak', CountryCode: 'SK' },
	'sl': { Language: 'Slovenian', CountryCode: 'SI' },
	'so': { Language: 'Somali', CountryCode: 'SO' },
	'es': { Language: 'Spanish', CountryCode: 'ES' },
	'es-MX': { Language: 'Spanish (Mexico)', CountryCode: 'MX' },
	'sw': { Language: 'Swahili', CountryCode: 'TZ' },
	'sv': { Language: 'Swedish', CountryCode: 'SE' },
	'ta': { Language: 'Tamil', CountryCode: 'IN' },
	'te': { Language: 'Telugu', CountryCode: 'IN' },
	'th': { Language: 'Thai', CountryCode: 'TH' },
	'tr': { Language: 'Turkish', CountryCode: 'TR' },
	'uk': { Language: 'Ukrainian', CountryCode: 'UA' },
	'ur': { Language: 'Urdu', CountryCode: 'PK' },
	'uz': { Language: 'Uzbek', CountryCode: 'UZ' },
	'vi': { Language: 'Vietnamese', CountryCode: 'VN' },
	'cy': { Language: 'Welsh', CountryCode: 'GB' },
}

export const getLanguageCodeFromLanguage = (language: Language) => {
	const locale = Object.keys(LANGUAGE_REGION_MAP).find((key) => LANGUAGE_REGION_MAP[key].Language === language)
	return locale
}

const SELECTED_LANGUAGE_KEY = 'selected-langauge'

const useLocalizationStore = () => {
	const [currentLanguage, setCurrentLanguage] = useLocalStorageState(SELECTED_LANGUAGE_KEY, Object.keys(LOCALES)[0])
	const [userCountry, setUserCountry] = useState('HK')

	const getUsersCountry = async() => {
		const { data: ip } = await axios.get('https://api.ipify.org')
		const countryText = await axios.get('https://ip2c.org/' + ip)
		const regexp = RegExp(/;(\w+);/)
		const match = regexp.exec(countryText.data)

		setUserCountry(match ? match[1] : 'HK')

		if(match && !localStorage.getItem(SELECTED_LANGUAGE_KEY)) {
			const lang: Language = LANGUAGE_REGION_MAP[match[1]].Language as Language || 'English'
			setCurrentLanguage(lang)
		}
	}

	useEffect(() => {
		//@ts-ignore
		if(!LOCALES[currentLanguage]) {
			setCurrentLanguage(Object.keys(LOCALES)[0])
		}

		getUsersCountry()
	}, [])

	return {
		locale: LOCALES[currentLanguage as Language] || {},
		currentLanguage,
		languages: Object.keys(LOCALES) as Language[],
		setCurrentLanguage,
		userCountry
	}
}

export const { context: LocalizationContext, contextMaker: LocalizationContextMaker } = ContextGenerator(useLocalizationStore)
