import { useMemo, useState } from 'react'

export default function(id: string, defaultValue: string) {
	const key = id
	const state = useState(localStorage.getItem(key) === null ? defaultValue : (localStorage.getItem(key) as string))
	const setCollapse = useMemo(() => (
		(value: string) => {
			localStorage.setItem(key, value.toString())
			state[1](value)
		}
	), [state[1]])

	const value = useMemo(() => ([state[0], setCollapse]), [state[0], setCollapse])
	return value as typeof state
}
