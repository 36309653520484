import { PropsWithChildren } from 'react'
import { Dialog as MuiDialog, SxProps, Theme } from '@mui/material'
import { DialogProps as MuiDialogProps } from '@mui/material'
import { themeShadows } from '../theme'

type DialogProps = {
	onClose: () => void
	paperSx?: SxProps<Theme>
} & MuiDialogProps

const Dialog = ({ onClose, children, sx, paperSx, ...props }: PropsWithChildren<DialogProps>) => {
	return (
		<MuiDialog
			{...props}
			sx={{ ...sx, boxShadow:themeShadows().z24 }}
			PaperProps={{ sx:{ borderRadius: '1rem', padding: '1rem', backgroundColor: '#fff', ...paperSx } }}
			onClose={onClose}>
			{children}
		</MuiDialog>
	)
}

export default Dialog