import { useContext, useEffect, useState } from 'react'
import { Configuration, ShopMetadataApi, ShopMetadataModel } from '@chatdaddy/client'
import { ContextGenerator } from '../Utils/ContextUtils'
import { LANGUAGE_REGION_MAP, LocalizationContext } from './LocalizationStore'
import { demoPhoneTeamId } from './ProductStore'

export const useShopMetaDataStore = () => {
	const [loading, setLoading] = useState(true)
	const { currentLanguage } = useContext(LocalizationContext)
	const configuration = new Configuration({})
	const shopMetaDataApi = new ShopMetadataApi(configuration)
	const [shopMetaData, setShopMetaData] = useState<ShopMetadataModel | undefined>(undefined)
	//is taken from url
	const [shopName, setShopName] = useState<string>()
	//if no teamId use scraper
	const [teamId, setTeamId] = useState<string>()
	const [isDemo, setIsDemo] = useState<boolean>(false)
	const [isTotalDemoShop, setIsTotalDemoShop] = useState<boolean>(false)
	const [urlPrefix, setUrlPrefix] = useState<string>('')
	const [isExternalShop, setIsExternalShop] = useState<boolean>(false)

	const getShopMetaData = async() => {
		setLoading(true)
		try {
			//first check by id as external shop will have id as shopName
			const { data } = await shopMetaDataApi.shopMetadataGet({ id: shopName })
			setShopMetaData(data.shopMetadata)
			setIsTotalDemoShop(false)
			setIsExternalShop(false)
		} catch(e) {
			try {
				//if not found by id, try to find by shopName means it is CD shop
				const { data } = await shopMetaDataApi.shopMetadataGet({ shopName })
				setTeamId(data.shopMetadata?.id)
				setShopMetaData(data.shopMetadata)
				setIsExternalShop(true)
				setIsTotalDemoShop(false)
			} catch(e) {
				//if not found by shopName, then we use demo shop
				const { data } = await shopMetaDataApi.shopMetadataGet({ id: demoPhoneTeamId })
				setShopMetaData(data.shopMetadata)
				setIsExternalShop(false)
				setIsTotalDemoShop(true)
			}
		}

		setLoading(false)
	}

	useEffect(() => {
		if(shopName) {
			getShopMetaData()
		}
	}, [shopName, isTotalDemoShop])

	useEffect(() => {
		if(shopMetaData?.shopName) {
			// set the shop name in the tab
			document.title = shopMetaData?.shopName
		}
	}, [shopMetaData?.shopName])

	useEffect(() => {
		//add meta tags
		if(shopMetaData?.shopName) {
			const meta = document.createElement('meta')
			meta.name = 'title'
			meta.content = `${shopMetaData?.shopName}`
			document.getElementsByTagName('head')[0].appendChild(meta)
			const meta2 = document.createElement('meta')
			meta.name = 'description'
			meta.content = 'A WhatsApp Shop powered by ChatDaddy',
			document.getElementsByTagName('head')[0].appendChild(meta2)
			//add og tags
			const meta3 = document.createElement('meta')
			meta.name = 'og:title'
			meta.content = `${shopMetaData?.shopName}`
			document.getElementsByTagName('head')[0].appendChild(meta3)
			const meta4 = document.createElement('meta')
			meta.name = 'og:description'
			meta.content = 'A WhatsApp Shop powered by ChatDaddy',
			document.getElementsByTagName('head')[0].appendChild(meta4)
			//add og url
			const meta5 = document.createElement('meta')
			meta.name = 'og:url'
			meta.content = `${window.location.href}`
			document.getElementsByTagName('head')[0].appendChild(meta5)
			//add title tag
			const meta6 = document.createElement('title')
			meta.name = 'title'
			meta.content = `${shopMetaData?.shopName}`
			document.getElementsByTagName('head')[0].appendChild(meta6)
		}

		if(shopMetaData?.shopImageUrl) {
			const meta = document.createElement('meta')
			meta.name = 'og:image'
			meta.content = shopMetaData?.shopImageUrl
			document.getElementsByTagName('head')[0].appendChild(meta)
		}
	}, [shopMetaData])

	useEffect(() => {
		if(shopName && currentLanguage) {
			// format is locale:Language
			const locale = Object.keys(LANGUAGE_REGION_MAP).find((key) => LANGUAGE_REGION_MAP[key].Language === currentLanguage)
			setUrlPrefix(`${locale}/${shopName}/${isDemo ? 'demo' : 'shop'}`)
		}
	}, [shopName, currentLanguage])

	return {
		loading,
		shopMetaData,
		getShopMetaData,
		shopName,
		setShopName,
		teamId,
		setIsDemo,
		isDemo,
		isTotalDemoShop,
		urlPrefix,
		isExternalShop,
		setIsExternalShop,
	}
}

export const { context: ShopMetaDataContext, contextMaker: ShopMetaDataContextMaker } = ContextGenerator(useShopMetaDataStore)
