import { useContext, useEffect, useMemo, useState } from 'react'
import {
	Configuration,
	PlatformProduct,
	ProductApi,
	ProductCategoriesApi,
	ProductCategory,
} from '@chatdaddy/client'
import axios from 'axios'
import { ContextGenerator } from '../Utils/ContextUtils'
import urls from '../Utils/urls'
import { AlertCentralContext } from './Alert'
import { ShopMetaDataContext } from './ShopMetadataStore'

export const demoPhoneTeamId = '2e1c5e1f-acfc-45da-b417-656a062b7113'

export const SORT_PRODUCT_MAP = [
	'pending-75dcdd63cafb0070',
	'pending-9529ae1f34a641a8',
	'pending-74002dc6f20098fa',
	'pending-c775e88f267bc573',
	'pending-59a99556f53be31f',
	'pending-494a8de40e3b74d6',
	'pending-91f60751d4b6e84e',
	'pending-b7554262a0f8f3d6',
	'pending-74a3cfb1d298a196',
	'pending-21bf228ab10e8edd',
	'pending-7fb8eae6b28c21da',
	'pending-82811a7fd60f972f',
	'pending-b63612ea9d29f31e',
	'pending-72532d9546ea2581',
	'pending-78ee0d09ee7783c0'
]

const useProductStore = () => {
	const alertCentral = useContext(AlertCentralContext)
	const { shopName, teamId, shopMetaData, isTotalDemoShop } =
    useContext(ShopMetaDataContext)
	const configuration = new Configuration({})
	const productCategoriesApi = new ProductCategoriesApi(configuration)
	const productsApi = new ProductApi(configuration)
	const [products, setProducts] = useState<PlatformProduct[]>([])
	const [productCategories, setProductCategories] = useState<ProductCategory[]>(
		[]
	)
	const [loadingFetch, setLoadingFetch] = useState(false)
	const [searchString, setSearchString] = useState<string>('')
	const [searchCategory, setSearchCategory] = useState<string>('')
	//USED TO POST ORDER TO SERVER
	const [trackingId, setTrackingId] = useState<string>()

	const hasNoProducts = useMemo(() => {
		return (
			products.length === 0 && !loadingFetch && !searchString && !searchCategory
		)
	}, [products, loadingFetch, searchString, searchCategory])

	// Todo: Add proper pagination for product store
	const getProducts = async() => {
		setLoadingFetch(true)
		try {
			if(teamId || shopName) {
				const { data } = await productsApi.platformProductsGet({
					q: searchString,
					category: searchCategory,
					returnTotalCount: true,
					teamId: teamId! || shopName!,
					count: 200
				})
				setProducts(data.products)
			} else if(
				shopName &&
        shopMetaData?.shopService &&
        shopMetaData?.externalShopUrl
			) {
				const { data } = await axios.get(
					urls.scraperUrl(shopMetaData.shopService),
					{
						params: {
							pageSize: 50,
							page: 1,
							shopUrl: shopMetaData?.externalShopUrl,
						},
					}
				)
				setProducts(data.products)
			} else {
				const { data } = await productsApi.platformProductsGet({
					q: searchString,
					category: searchCategory,
					returnTotalCount: true,
					teamId: demoPhoneTeamId,
					count: 200
				})
				setProducts(data.products)
			}
		} catch(e) {
			console.log(e)
		}

		setLoadingFetch(false)
	}

	const filterProducts = (products: PlatformProduct[]) => {
		if(teamId) {
			return products.filter((product) => !product.isHidden)
		}

		// filter extername + demo shop
		if(searchString || searchCategory) {
			return products
				.filter((product) => {
					if(searchString && searchCategory) {
						return (
							product.name.toLowerCase().includes(searchString.toLowerCase()) &&
              product.categories?.find(
              	(category) => category.id === searchCategory
              )
						)
					} else if(searchString) {
						return product.name
							.toLowerCase()
							.includes(searchString.toLowerCase())
					} else if(searchCategory) {
						return product.categories?.find(
							(category) => category.id === searchCategory
						)
					}

					return false
				})
				.filter((product) => !product.isHidden)
		} else {
			return products.filter((product) => !product.isHidden)
		}
	}

	const getProductCategories = async() => {
		if(teamId || shopMetaData?.id) {
			try {
				const {
					data: { categories },
				} = await productCategoriesApi.platformProductCategoriesGet({
					teamId: teamId! || shopMetaData?.id!,
				})
				setProductCategories(categories)
			} catch(e) {
				alertCentral.error(e, 2000)
			}
		} else if(isTotalDemoShop) {
			try {
				const {
					data: { categories },
				} = await productCategoriesApi.platformProductCategoriesGet({
					teamId: demoPhoneTeamId,
				})

				setProductCategories(categories)
			} catch(e) {
				alertCentral.error(e, 2000)
			}
		}
	}

	useEffect(() => {
		if(shopMetaData?.id) {
			getProducts()
			getProductCategories()
		}
	}, [
		shopMetaData?.externalShopUrl,
		shopMetaData?.shopService,
		shopMetaData?.id,
	])

	useEffect(() => {
		if(teamId) {
			getProducts()
		}
	}, [searchCategory, searchString])

	useEffect(() => {
		setTrackingId('')
	}, [shopName])

	return {
		teamId,
		products,
		searchString,
		setSearchString,
		searchCategory,
		setSearchCategory,
		productCategories,
		getProducts,
		loadingFetch,
		hasNoProducts,
		trackingId,
		filterProducts,
	}
}

export const {
	context: ProductStoreContext,
	contextMaker: ProductStoreContextMaker,
} = ContextGenerator(useProductStore)
