import React, { useContext } from 'react'
import { Close } from '@mui/icons-material'
import { Checkbox, Typography } from '@mui/material'
import Dialog from '../Components/Dialog'
import Grid from '../Components/Grid'
import IconButton from '../Components/IconButton'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import { UIContext } from '../Controllers/UIContext'

const ShopNotice = () => {
	const { shopMetaData } = useContext(ShopMetaDataContext)
	const { dontShowNoticeAgain, setDontShowNoticeAgain, showNotice, setShowNotice } = useContext(UIContext)

	return (
		<Dialog
			open={showNotice}
			paperSx={
				{
					backgroundColor: 'primaryGradient.50',
				}
			}
			onClose={() => setShowNotice(false)}>
			<Grid
				position='relative'
				flexcolumn
				gap={4}>
				<IconButton
					sx={
						{
							position: 'absolute',
							top: 0,
							right: 0,
						}
					}
					onClick={() => setShowNotice(false)}>
					<Close />
				</IconButton>
				<Grid
					flexcenter
					fullwidth>
					<Grid
						flexcenter
						sx={
							{
								fontSize:'2rem',
								height: '4.5rem',
								width: '4.5rem',
								borderRadius: '50%',
								backgroundColor: 'primaryGradient.200',
							}
						}>
						🎉
					</Grid>
				</Grid>
				<Grid>
					<Typography
						sx={
							{
								whiteSpace: 'pre-line',
							}
						}>
						{shopMetaData?.shopNotice}
					</Typography>
				</Grid>
				<Grid
					flexstartcenter
					gap={2}>
					<Checkbox
						checked={dontShowNoticeAgain === 'true'}
						onChange={(e) => setDontShowNoticeAgain(e.target.checked.toString())} />
					<Typography>
						{'Don\'t show this again'}
					</Typography>
				</Grid>
			</Grid>
		</Dialog>
	)
}

export default ShopNotice