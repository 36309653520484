import { useContext, useEffect, useMemo, useState } from 'react'
import { LocalShippingOutlined } from '@mui/icons-material'
import { Checkbox, Divider, Typography } from '@mui/material'
import Grid from '../Components/Grid'
import TextField from '../Components/TextField'
import { CheckoutStoreContext } from '../Controllers/CheckoutStore'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { ShopMetaDataContext } from '../Controllers/ShopMetadataStore'
import { CustomTextField } from './CheckoutDetails'
import InputSectionAccordian from './InputSectionAccordian'


type ShippingProps = {
    expanded: 'shippingDetails' | 'paymentMethod' | 'contact' | undefined
    setExpanded: (value: 'shippingDetails' | 'paymentMethod' | 'contact' | undefined) => void
    shippingDetailsValidation: boolean
}
const Shipping = ({ expanded, setExpanded, shippingDetailsValidation }: ShippingProps) => {
	const { shopMetaData } = useContext(ShopMetaDataContext)
	const { shippingDetails, setShippingDetails, sameAsContact, setSameAsContact, freeShipping } = useContext(CheckoutStoreContext)
	const { locale } = useContext(LocalizationContext)
	const [chosen, setChosen] = useState<'pickup' | 'delivery'>()
	const showDelivery = useMemo(() => (shopMetaData?.shippingEnabled && !shopMetaData.pickUpEnabled) || (shopMetaData?.shippingEnabled && chosen === 'delivery'), [shopMetaData, chosen])
	const showPickup = useMemo(() => (shopMetaData?.pickUpEnabled && !shopMetaData.shippingEnabled) || (shopMetaData?.pickUpEnabled && chosen === 'pickup'), [shopMetaData, chosen])

	useEffect(() => {

		if(shopMetaData?.pickUpEnabled) {
			setChosen('pickup')
		} else if(shopMetaData?.shippingEnabled) {
			setChosen('delivery')
		}

	}, [shopMetaData])

	useEffect(() => {
		if(chosen === 'delivery') {

			const deliveryFees = freeShipping ? 0 : shopMetaData?.shippingFee?.filter(shipping => shipping.enabled)[0]?.amount || 0
			setShippingDetails({ ...shippingDetails, shippingOption: shopMetaData?.shippingFee?.filter(shipping => shipping.enabled)[0]?.name, deliveryFees, pickupLocation: undefined, shippingMethod:chosen })

		} else if(chosen === 'pickup') {
			setShippingDetails({ ...shippingDetails, shippingOption: undefined, deliveryFees: 0, pickupLocation: shopMetaData?.pickUpAddresses?.filter(a => a.enabled)[0].address, shippingMethod:chosen })
		}
	}, [chosen])


	if(!shopMetaData?.pickUpEnabled && !shopMetaData?.shippingEnabled) {
		return null
	}

	return (
		<InputSectionAccordian
			expanded={expanded === 'shippingDetails'}
			setExpanded={() => setExpanded(expanded === 'shippingDetails' ? undefined : 'shippingDetails')}
			passedValidation={shippingDetailsValidation}
			goNext={() => setExpanded('paymentMethod')}
			icon={<LocalShippingOutlined />}
			title={`${locale.shipping} ${locale.details}`}>
			<Grid>
				{
					shopMetaData?.pickUpEnabled && shopMetaData?.shippingEnabled && (
						<>
							<Grid
								py={2}
								flexevenlycenter>
								<Grid
									flexstartcenter>
									<Checkbox
										onChange={() => setChosen('pickup')}
										checked={chosen === 'pickup'}
										color='primary'
										sx={{ mr: 2 }}
									/>
									<Typography
										variant='body2'
									>
										{locale.pickup}
									</Typography>
								</Grid>
								<Grid
									flexstartcenter>
									<Checkbox
										onChange={() => setChosen('delivery')}
										checked={chosen === 'delivery'}
										color='primary'
										sx={{ mr: 2 }}
									/>
									<Typography
										variant='body2'
									>
										{locale.delivery}
									</Typography>
								</Grid>
							</Grid>
							<Divider />
						</>
					)
				}
				{
					showDelivery && (
						<>
							<Grid
								flexstartcenter>
								<Checkbox
									onChange={() => setSameAsContact(prev => !prev)}
									checked={sameAsContact}
									color='primary'
									sx={{ mr: 2 }}
								/>
								<Typography
									variant='body2'
									color='text.secondary'
								>
									{locale.sameAsContact}
								</Typography>
							</Grid>
							<CustomTextField
								forceShrink
								value={shippingDetails?.name}
								onChange={(e) => setShippingDetails({ ...shippingDetails, name: e.target.value })}
								sx={{ mt: 4 }}
								fullWidth
								label={locale.fullName}
								placeholder={locale.nameHere}
							/>
							<TextField
								value={shippingDetails?.mobileNumber}
								onChange={(e) => setShippingDetails({ ...shippingDetails, mobileNumber: e.target.value })}
								fullWidth
								type='phone'
								forceShrink
								label='Whatsapp Phone Number'
								placeholder={locale.inputNumber} />
							<CustomTextField
								forceShrink
								value={shippingDetails?.shippingAddress}
								onChange={(e) => setShippingDetails({ ...shippingDetails, shippingAddress: e.target.value })}
								fullWidth
								label={locale.shippingAddress}
								placeholder={locale.addressHere}
							/>
							<Typography
								variant='body2'>
								{locale.shippingOptions}
							</Typography>
							{
								shopMetaData?.shippingFee?.filter(shipping => shipping.enabled).map((shippingOption) => {
									return (
										<Grid
											key={shippingOption.name}
											py={2}
											flexstartcenter>
											<Checkbox
												onChange={() => setShippingDetails({ ...shippingDetails, shippingOption: shippingOption.name, deliveryFees:shippingOption.amount || 0 })}
												checked={shippingDetails.shippingOption === shippingOption.name}
												color='primary'
											/>
											<Grid
												flexbetweencenter
												fullwidth>
												<Typography
													variant='body2'
													color='text.secondary'
												>
													{shippingOption.name}
												</Typography>
												<Typography
													variant='body2'
													color='text.secondary'
												>
													{`${shippingOption.currency} ${freeShipping ? 0 : shippingOption.amount}`}
												</Typography>
											</Grid>
										</Grid>
									)
								})
							}
						</>
					)
				}
				{
					showPickup && (
						<>
							<Grid
								flexcolumn>
								<Typography
									variant='body2'
									sx={{ mt: 4 }}>
									{locale.pickupLocations}
								</Typography>
								{
									shopMetaData?.pickUpAddresses?.filter(a => a.enabled).map((address) => {
										return (
											<Grid
												key={address.address}
												py={2}
												flexstartcenter>
												<Checkbox
													onChange={() => setShippingDetails({ ...shippingDetails, pickupLocation: address.address })}
													checked={shippingDetails.pickupLocation === address.address}
													color='primary'
													sx={{ mr: 2 }}
												/>
												<Typography
													variant='body2'
													color='text.secondary'
												>
													{address.address}
												</Typography>
											</Grid>
										)
									})
								}
							</Grid>
						</>
					)
				}
			</Grid>
		</InputSectionAccordian>
	)
}

export default Shipping