import { useContext } from 'react'
import { MenuItem, Select, Typography } from '@mui/material'
import Flags from 'country-flag-icons/react/3x2'
import countries from 'i18n-iso-countries'
import { LocalizationContext } from '../Controllers/LocalizationStore'
import { PaymentStoreContext } from '../Controllers/PaymentIntegrationStore'
import { themeShadows } from '../theme'
import Grid from './Grid'


export const PaymentCountrySelect = ({ currentCountry, setCurrentCountry }: { currentCountry: string | undefined, setCurrentCountry: (val: string | undefined) => void }) => {
	const { locale } = useContext(LocalizationContext)
	const { paymentSystems } = useContext(PaymentStoreContext)
	const countriesAvailable = Array.from(new Set(paymentSystems.map(itm => itm.country)))

	const ALL_COUNTRIES = countriesAvailable
		.map((countryCode) => {
			const FlagComponent = Flags[countryCode as keyof typeof Flags]
			return {
				value: countryCode,
				disabled: false,
				label: countries.getName(countryCode, 'en'),
				beforeComponent: FlagComponent
					? (
						<FlagComponent
							style={
								{
									height: '0.7rem',
									width: '1rem',
									marginRight: '0.5rem'
								}
							} />
					)
					: undefined
			}
		})

	const options = [{
		value: 'all',
		disabled: false,
		label: locale.all,
		beforeComponent: null
	}, ...ALL_COUNTRIES]
	return (
		<Select
			sx={{ minHeight:0, height:'2.5rem', width:'15rem', borderRadius:'0.5rem', backgroundColor:'background.paper' }}
			value={currentCountry}
			onChange={(e) => setCurrentCountry(e.target.value)}
			renderValue={
				(val) => {
					const country = options.find(itm => itm.value === val)
					return (
						<Grid
							flexstartcenter
							gap={2}>
							{country?.beforeComponent}
							<Typography
								variant='baseRegular'
								sx={
									{
										textOverflow: 'ellipsis', whiteSpace:'nowrap', overflow:'hidden'
									}
								}>
								{country?.label}
							</Typography>
						</Grid>
					)
				}
			}
			MenuProps={
				{
					PaperProps: { sx:{ maxHeight:'10rem', width:'15rem', boxShadow:themeShadows().dropdownShadow } }
				}
			}
		>
			{
				options.map((country, idx) => {
					return (
						<MenuItem
							value={country.value}
							key={idx}>
							<Grid
								flexcenter
								gap={2}>
								{country.beforeComponent}
								<Typography
									variant='baseRegular'
									sx={
										{
											textOverflow: 'ellipsis', whiteSpace:'nowrap', overflow:'hidden', maxWidth:'10rem'
										}
									}>
									{country.label}
								</Typography>
							</Grid>
						</MenuItem>
					)
				})
			}
		</Select>
	)
}