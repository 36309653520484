import { useContext, useEffect, useState } from 'react'
import { PlatformProduct } from '@chatdaddy/client'
import { ContextGenerator } from '../Utils/ContextUtils'
import useLocalStorageState from '../Utils/useLocalStorageState'
import { ShopMetaDataContext } from './ShopMetadataStore'

export type CartItem = {item: PlatformProduct, quantity: number, note?: string}
const useCartStore = () => {

	const { shopMetaData } = useContext(ShopMetaDataContext)

	const [localStorageCart, setLocalStorageCart] = useLocalStorageState('cart', '{}')
	const [cart, setCartState] = useState<CartItem[]>([])
	const [cartNote, setCartNote] = useLocalStorageState('cartNote', '')
	const [promoCode, setPromoCode] = useLocalStorageState('promoCode', '')
	const setCart = (cart: CartItem[]) => {

		const cartDetails = {
			id : shopMetaData?.id,
			cart: cart
		}

		setLocalStorageCart(JSON.stringify(cartDetails))
		setCartState(cart)
	}

	const resetCart = () => {

		setCart([])
		setCartNote('')
		setPromoCode('')
	}

	const addToCart = (item: PlatformProduct) => {
		const cartItem = cart.find(cartItem => cartItem.item.id === item.id)
		if(cartItem) {
			cartItem.quantity += 1
			setCart([...cart])
		} else {
			setCart([...cart, { item, quantity: 1 }])
		}
	}

	const removeFromCart = (item: PlatformProduct, removeAll?: boolean) => {
		if(removeAll) {
			setCart(cart.filter(cartItem => cartItem.item.id !== item.id))
		} else {
			const cartItem = cart.find(cartItem => cartItem.item.id === item.id)
			if(cartItem) {
				if(cartItem.quantity > 1) {
					cartItem.quantity -= 1
					setCart([...cart])
				} else {
					setCart(cart.filter(cartItem => cartItem.item.id !== item.id))
				}
			}
		}
	}

	const updateCartItemNote = (item: PlatformProduct, note: string) => {
		const cartItem = cart.find(cartItem => cartItem.item.id === item.id)
		if(cartItem) {
			cartItem.note = note
			setCart([...cart])
		}
	}

	useEffect(() => {
		const cartDetails: {id: string, cart: CartItem[]} = JSON.parse(localStorageCart)
		if(cartDetails.id === shopMetaData?.id && cartDetails.cart) {
			setCartState(cartDetails.cart)
		}
	}, [shopMetaData?.id])


	return {
		cart,
		addToCart,
		removeFromCart,
		updateCartItemNote,
		cartNote,
		setCartNote,
		promoCode,
		setPromoCode,
		resetCart
	}
}

export const { context: CartStoreContext, contextMaker: CartStoreContextMaker } = ContextGenerator(useCartStore)
