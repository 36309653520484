import axios from 'axios'

export interface ModelError {
    'statusCode': number
    'error': string
    'message': string
    'data'?: object
}

export default (error: Error) => {
	let message: string
	if(axios.isAxiosError(error)) {
		const obj = error.response?.data as ModelError
		message = obj?.error || obj?.message || error.message
	} else if(error.message?.toLowerCase() === 'failed to fetch') {
		message = 'No internet connection!'
	} else if(error.message) {
		message = error.message
	} else {
		message = 'Unknown Error'
	}

	return message
}
